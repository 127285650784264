<template>
	
	<div>
	
		<div style="text-align: right;">
			<Input placeholder="请输入手机号码" style="width: 300px;" v-model="phone"></Input>
			<Button @click="()=>to_memberQuery()" style="margin-left: 10px;" type='primary'>查询</Button>
			<Button @click="()=>table_dowload()" v-if="infos" style="margin-left: 10px;">导出全部数据</Button>
		</div>
		
		<!-- 号码信息 -->
		<div style="margin-top: 10px;">
			<Card>
				<pre>
				微信名称:{{infos.userName}}
				用户串码:{{infos.openid}}
				电话号码:{{infos.phone}}
			        创建时间:{{infos.createTime}}
				</pre>
				<div style="overflow: hidden; border-radius: 500px;  position: absolute;top: 20px;left: 50px;width: 100px;height: 100px;">
					<img style="width: 100%;height: 100%;" :src="infos.userIcon?infos.userIcon:require('../../../assets/images/no_img.png')" >
				</div>
			</Card>
		</div>
	
		<!-- 查询列表 -->
		<div style="margin-top: 10px;">
			<Card>
				<Table :columns='title' :data='list'>
					
					<template slot-scope="{ row, index }" slot='operation'>
						<div>
							<Button @click="()=>(keyword=row.orderid)&&to_GetOrderDatab()" type="primary">查看详情</Button>
						</div>
					</template>
					
				</Table>
				<div v-if="total" style="text-align: right;margin-top: 10px;">
					<Page :total='total' @on-change="($)=>to_memberQuery($)"></Page>
				</div>
				
			</Card>
		</div>
		
		<!-- 详情查看 -->
		<div >
			
			<!-- 操作区域 -->
			<div>
				<Input v-show="false" @on-enter='()=>to_GetOrderDatab()' autofocus v-model="keyword" style="width: 300px;" placeholder="请输入订单号"></Input>
			</div>
			
			<div v-show="order.orderid" style="margin-top: 30px;">
				
				<Card>
					<!-- 电话号码 -->
					<div v-if="order.mobilePhone">
						<p style="border-left: 5px solid black;padding-left: 10px;margin: 10px 0;">电话号码
			
						</p>
						<div style="padding-left: 20px;">
							{{order.mobilePhone}}
						</div>
					</div>
					<!-- 订单信息 -->
					<div>
						<p style="border-left: 5px solid black;padding-left: 10px;margin: 10px 0;">订单信息
							<!-- order -->
						</p>
						<div style="padding-left: 20px;position: relative;">
			
							订单号 {{order.orderid}}
							<br> 订单名称
							{{order.orderName}}
							<br />
							订单描述 <span v-if="order.orderAbout" v-html="orderAbout(order.orderAbout)"></span>
			
							<br>
			
							订单状态 <Tag v-if='order.state' :color="state_color[order.state]">{{state[order.state]}}</Tag>
			
							<br>
			
							订单创建时间 {{$formatDate(order.createTime,"yyyy年MM月dd日 HH:mm")}}
							<br>
							支付类型 <Tag color="orange">{{order.pay}}</Tag>
							<br>
							订单价格 {{order.orderPrice/100}}元
							<br>
							实付金额 {{order.cash_fee/100}}元
				
							
			
			
			
							<div style="width: 200px ;height: 300px;position: absolute;top: -20px;right: 0;">
								<img style="width: 100%;height: 100%;" :src="order.imgurl">
							</div>
						</div>
					</div>
					<div v-if="order.qrCode!=''">
					
						<p style="border-left: 5px solid black;padding-left: 10px;margin: 10px 0;">取货码
							<!-- gooslist couponslist-->
						</p>
						<div style="padding-left: 20px;">
							{{order.qrCode}}
						</div>
					</div>
					<div v-if="order.retime!=''">
						<p style="border-left: 5px solid black;padding-left: 10px;margin: 10px 0;">退款信息</p>
						<div style="padding-left: 20px;">
							退款金额 <span style="color: red;">{{order.refund}}</span> 元
							<br>
							退款时间 {{order.retime}}
							<br>
							退款说明 {{order.retNote}}
							<br>
							退款人 {{order.retName}}
						</div>
					</div>
					<div>
						<p style="border-left: 5px solid black;padding-left: 10px;margin: 10px 0;">订单备注
						</p>
						<div style="padding-left: 20px;">
			
							内部ID {{order._id}}
			
							<br />
							用户串码 {{order.openid}}
							<br>
							影院编号 {{order.mcode}}
							<br>
							{{order.Note}}
			
						</div>
					</div>
					<!-- 商品 票券信息 -->
					<div>
						
						<p style="border-left: 5px solid black;padding-left: 10px;margin: 10px 0;">商品/票券信息
							<!-- gooslist couponslist-->
						</p>
						<div style="padding-left: 20px;">
						</div>
						
					</div>
				</Card>
				
			</div>
			
			<!-- 表格显示区域 -->
			
			<div style="margin-top: 30px;" v-for="item in order.gooslist">
				
				<Table stripe :columns="item.title" :data="item.list"></Table>
				
			</div>
			
			<div>
				
				<!-- 单笔退款弹出 -->
				<Modal v-model="modal1" title="退款">
					<p><span style="margin-right: 10px;">退款备注</span>
						<Input v-model="note_refund" style="width: 300px;" placeholder="请输入退款备注"></Input>
					</p>
					<div slot="footer">
						<Button @click="()=>modal1=false"> 取消</Button>
						<Button type='primary' @click="()=>to_refund('退款')"> 确定</Button>
					</div>
				</Modal>
			
				<!-- 全额退款弹出 -->
				<Modal v-model="modal2" title="退款订单">
					<p><span style="margin-right: 10px;">退款备注</span>
						<Input v-model="note_refund_all" style="width: 300px;" placeholder="请输入退款备注"></Input>
					</p>
					<div slot="footer">
						<Button @click="()=>modal2=false"> 取消</Button>
						<Button type='primary' @click="()=>to_refund('全部退款')"> 确定</Button>
					</div>
				</Modal>
			
			</div>
			
		</div>
		
		<div style="height: 50px;width: 100vw;"> </div>
		
	</div>
	
</template>

<script>
	
	import { download } from '../../../utils/table_download.js'
	import {GetOrderDatab,OrderRefund, memberQueryv1,memberOrderQueryv1 } from '../../../request/api.js'
	export default{
		name:'页面_会员查询',
		data() {
			
			return{
				total:'',//查询总长度
				infos:'',//电话查询信息
				title:[],//
				list:[],//
				phone:'',//查询号码
				note_refund_all: '', //全额退款备注
				note_refund: '', //退款备注
				row_detail: {},
				modal2: false,
				modal1: false,
				state_color: {
					12: '',
					1: 'warning',
					2: 'primary',
					4: 'error',
					5: 'default',
					6: 'error'
				},
				state: {
					12: "全部", //
					1: '未支付', //黄色    <Tag color="warning">warning</Tag>
					2: "已支付", //蓝色<Tag color="primary">primary</Tag>
					4: "已退款", //红色  <Tag color="error">error</Tag>
					5: "已关闭", //灰色 <Tag color="default">default</Tag>
					6: '部分退款'
				},
				order: {}, //订单对象信息
				keyword: '', //订单号
			}
			
		},
		computed: {
			
			orderAbout() {
				return function(orderAbout) {
					return orderAbout.join(' ')
				}
			},
			
			order_state_tag() {
				return function(index) {
					return `<Tag color="${this.state_color[index]}">${this.state[index]}</Tag>`
				}
			}
			
		},
		methods: {
			
			async table_dowload(){
				
				let pass_data = {
					openid:this.infos.openid,
					"page":1,
					"all":1
				}
				
				let {data:res_data} = await memberOrderQueryv1(pass_data) 
				
				let headers = res_data.title
				let data  = res_data.list.map(item=>{
					return {
						"orderid":item.orderid,
						"orderName":item.orderName,
						"createTime":item.createTime,
						"state":item.state,
						"pay":item.pay,
						"cash_fee":item.cash_fee
					}
				})
				download({
				  'worksheet':this.infos.phone+'',
				  headers,
				  data
			    })
				
			},
			
			async to_memberQuery(page=1) {
				
				let pass_data = {
					"phone":this.phone,
					
				}
				
				let {data:res_data} = await memberQueryv1(pass_data)
			
				this.infos =res_data[0]
				let pass_data1 = {
					openid:res_data[0]['openid'],
					"page":page
				}
				let {data:res_data1} = await memberOrderQueryv1(pass_data1) 
				this.total = res_data1.total
				res_data1.title.push({
					key:'operation',
					title:'操作',
					slot:'operation'
				})
				this.title = res_data1.title
				this.list = res_data1.list
				
			},
			
			
			refund_single(row) {
			
				this.modal1 = true
				this.row_detail = row
				
			},
			
			async to_refund(type) {
			
				if (type == '全部退款') {
					if (!this.note_refund_all) {
						this.$mytoast_err('请输入备注信息')
						return
					}
					let pass_data = {
						'retName':JSON.parse(localStorage.getItem('选中品牌1')).user.name,
						"orderid": this.order.orderid, //退款订单号
						"all": true, //全部退款 优先级高于uidList
						"uidList": [], //退款订单内某个商品如果传了all=true 此部分退款无效
						"retNote": this.note_refund_all //退款备注
					}
					
					let res_data = await OrderRefund(pass_data);
					
					if (res_data.code != 200) {
						this.$mytoast(res_data.msg)
						return
					}
					if (res_data.msg == 'ok') {
						this.$mytoast('退款成功')
					}
					this.keyword = res_data.data.orderid
					this.to_GetOrderDatab()
					this.modal2 = false
			
				}
				if (type == '退款') {
					if (!this.note_refund) {
						this.$mytoast_err('请输入备注信息')
						return
					}
			
					let pass_data = {
						'retName':JSON.parse(localStorage.getItem('选中品牌1')).user.name,
						"orderid": this.order.orderid, //退款订单号
						"all": false, //全部退款 优先级高于uidList
						"uidList": [this.row_detail], //退款订单内某个商品如果传了all=true 此部分退款无效
						"retNote": this.note_refund //退款备注
					}
					let res_data = await OrderRefund(pass_data);
			
					if (res_data.code != 200) {
						this.$mytoast(res_data.msg)
						return
					}
					if (res_data.msg == 'ok') {
						this.$mytoast('退款成功')
					}
					this.keyword = res_data.data.orderid
					this.to_GetOrderDatab()
					this.modal1 = false
				}
			
			},
			
			async to_GetOrderDatab() {
				
				if (!this.keyword) {
					this.$mytoast_err('请输入订单号查询')
					return
				}
				let pass_data = {
					'keyword': this.keyword
				}
				
				let res_data = await GetOrderDatab(pass_data);
				
				if (res_data.code == 200) {
					let center = JSON.parse(JSON.stringify(res_data.data))
					console.log(center)
					center.gooslist.forEach((item, index) => {
						center.gooslist[index].title.push({
							key: "operation",
							title: "操作",
							slot: 'operation'
						})
					})
					this.order = center
				} else {
					this.$mytoast_err(res_data.msg)
				}
				
			}
		
		},
		
	}
</script>

