import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ViewUI from 'view-design';
import store from './store'
import axios from 'axios'
import 'view-design/dist/styles/iview.css';
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'



Vue.use(VueQuillEditor);
Vue.config.productionTip = false
Vue.use(ViewUI);
Vue.config.$store = store


Vue.prototype.$mytype=()=>{
	return JSON.parse(localStorage.getItem('选中品牌1')).user.page
}

// 按钮权限检查
Vue.prototype.$my_checkBtn = (name)=>{
	// 1类用户  开发人员
	if(localStorage.getItem('type')==1){
		return true
	}
	// 2类用户
	if(localStorage.getItem('type')==2){
		let center = JSON.parse(localStorage.getItem('current_page_button'))
		return Object.keys(center).indexOf(name)!=-1?true:false
	}
	
}

Vue.prototype.$my_height = ()=>{
	return window.innerHeight
}

Vue.prototype.$my_width = ()=>{
	return window.innerWidth
}

Vue.prototype.$mytoast_err = (pass_data)=>{
	Vue.prototype.$Message.error(pass_data)
}	

Vue.prototype.$mytoast = (pass_data)=>{
	Vue.prototype.$Message.success(pass_data)
}	

// 时间处理函数
Vue.prototype.$formatDate = (source, format)=> {
		source = new Date(parseInt(source));
		const o = {
			//     'Y+': source.getFullYear(),
			'M+': source.getMonth() + 1, // 月份
			'd+': source.getDate(), // 日
			'H+': source.getHours(), // 小时
			'm+': source.getMinutes(), // 分
			's+': source.getSeconds(), // 秒
			'q+': Math.floor((source.getMonth() + 3) / 3), // 季度
			'f+': source.getMilliseconds() // 毫秒
		}
		if (/(y+)/.test(format)) {
			format = format.replace(RegExp.$1, (source.getFullYear() + '').substr(4 - RegExp.$1
				.length))
		}
		for (let k in o) {
			if (new RegExp('(' + k + ')').test(format)) {
				format = format.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k])
					.substr(('' +
						o[k]).length)))
			}
		}
		return format
	}
Date.prototype.Format = function(fmt)   
  { //author: meizz   
	var o = {   
	 "M+" : this.getMonth()+1,                 //月份   
	 "d+" : this.getDate(),                    //日   
	 "h+" : this.getHours(),                   //小时   
	 "H+" : this.getHours(),
	 "m+" : this.getMinutes(),                 //分   
	 "s+" : this.getSeconds(),                 //秒   
	 "q+" : Math.floor((this.getMonth()+3)/3), //季度   
	 "S"  : this.getMilliseconds()             //毫秒   
   };   
   if(/(y+)/.test(fmt))   
	 fmt=fmt.replace(RegExp.$1, (this.getFullYear()+"").substr(4 - RegExp.$1.length));   
   for(var k in o)   
	 if(new RegExp("("+ k +")").test(fmt))   
   fmt = fmt.replace(RegExp.$1, (RegExp.$1.length==1) ? (o[k]) : (("00"+ o[k]).substr((""+ o[k]).length)));   
   return fmt;   
 }
if(window.location.host.split(':')[0]=='localhost'){
	// 上传图片地址
	Vue.prototype.$uploadImg = '106.52.48.79:39003/'
	// Vue.prototype.$uploadImg = '1.116.228.161:10090/';//旧版

}else{
	// 上传图片地址
	Vue.prototype.$uploadImg = window.location.host+'/cms/'
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
