<template>
	<div>
		<!-- 操作区域 -->
		<div style="text-align: right;">
			<Input @on-enter='()=>to_GetOrderDatab()' autofocus v-model="keyword" style="width: 300px;" placeholder="请输入订单号"></Input>
			<Button @click="to_GetOrderDatab" style="margin-left: 10px;" type="primary">查找</Button>
		</div>
		<!-- 当前查询订单信息 -->
		<div v-show="order.orderid" style="margin-top: 30px;">
			<Card>
				<!-- 电话号码 -->
				<div v-if="order.mobilePhone">
					<p style="border-left: 5px solid black;padding-left: 10px;margin: 10px 0;">电话号码</p>
					<div style="padding-left: 20px;">
						{{order.mobilePhone}}
					</div>
				</div>
				<!-- *订单信息 -->
				<div>
					<p style="border-left: 5px solid black;padding-left: 10px;margin: 10px 0;">订单信息</p>
					<div style="padding-left: 20px;position: relative;">
						订单号 {{order.orderid}}
						<br> 订单名称
						{{order.orderName}}
						<br />
						订单描述 <span v-if="order.orderAbout" v-html="orderAbout(order.orderAbout)"></span>
						<br>
						订单状态 <Tag v-if='order.state' :color="state_color[order.state]">{{state[order.state]}}</Tag>
						<br>
						订单创建时间 {{$formatDate(order.createTime,"yyyy年MM月dd日 HH:mm")}}
						<br>
						支付类型 <Tag color="orange">{{order.pay}}</Tag>
						<br>
						订单价格 {{order.orderPrice/100}}元
						<br>
						实付金额 {{order.cash_fee/100}}元
						<div style="width: 200px ;height: 300px;position: absolute;top: -20px;right: 0;">
							<img style="width: 100%;height: 100%;" :src="order.imgurl">
						</div>
					</div>
				</div>
				<!-- 取货码 显示/隐藏 -->
				<div v-if="order.qrCode">
					<p style="border-left: 5px solid black;padding-left: 10px;margin: 10px 0;">取货码</p>
					<div style="padding-left: 20px;"> {{order.qrCode}} </div>
				</div>
				<!-- 退款信息 显示/隐藏 -->
				<div v-if="order.retime">
					<p style="border-left: 5px solid black;padding-left: 10px;margin: 10px 0;">退款信息</p>
					<div style="padding-left: 20px;">

						退款金额 <span style="color: red;">{{order.refund}}</span> 元
						<br>
						退款时间 {{order.retime}}
						<br>
						退款说明 {{order.retNote}}
						<br>
						退款人 {{order.retName}}
					</div>
					
				</div>
				<!-- *订单备注 -->
				<div >
					
					<p style="border-left: 5px solid black;padding-left: 10px;margin: 10px 0;">订单备注</p>
					<div style="padding-left: 20px;">
						内部ID {{order._id}}
						<br />
						用户串码 {{order.openid}}
						<br>
						影院编号 {{order.mcode}}
						<br>
						{{order.Note}}
					</div>
					
				</div>
				<!-- 商品/票券信息 -->
				<div>
					<p style="border-left: 5px solid black;padding-left: 10px;margin: 10px 0;">商品/票券信息</p>
					<div style="padding-left: 20px;"></div>
				</div>
				<div v-if="order.aRefund" style="position: absolute;right: 10px;bottom: 10px;">
					<Button @click="()=>modal_refund_all=true" type='error'>订单退款</Button>
				</div>
			</Card>

		</div>
		<!-- 当前查询表格主体信息 -->
		<div :key="index" style="margin-top: 30px;" v-for="(item,index) in order.gooslist">
			<Table stripe :columns="item.title" :data="item.list">
				<template slot-scope="{ row }" slot='operation'>
					<div v-if="row.aRefund">
						<Button @click="()=>(row_detail=row.uid)&&(modal_refund=true)" type='error'>退款</Button>
					</div>
				</template>
			</Table>
		</div>
		<!-- 弹窗 -->
		<div>
			<!-- 单笔退款弹出 -->
			<Modal :closable='false' :mask-closable='false' v-model="modal_refund" title="退款">
				<p>
					<span style="margin-right: 10px;">退款备注</span>
					<Input v-model="note_refund" style="width: 300px;" placeholder="请输入退款备注"></Input>
				</p>
				<div slot="footer">
					<Button @click="()=>modal_refund=false"> 取消</Button>
					<Button type='primary' @click="()=>to_refund('退款')"> 确定</Button>
				</div>
			</Modal>
			<!-- 全额退款弹出 -->
			<Modal  :closable='false' :mask-closable='false' v-model="modal_refund_all" title="退款订单">
				<p>
					<span style="margin-right: 10px;">退款备注</span>
					<Input v-model="note_refund_all" style="width: 300px;" placeholder="请输入退款备注"></Input>
				</p>
				<div slot="footer">
					<Button @click="()=>modal_refund_all=false"> 取消</Button>
					<Button type='primary' @click="()=>to_refund('全部退款')"> 确定</Button>
				</div>
			</Modal>
		</div>
	</div>
</template>

<script>
	
	import { GetOrderDatab, OrderRefund } from '@/request/api.js'
	export default {
		name: 'navbar',
		data() {
			
			return {
				note_refund_all: '', //绑定全额退款备注
				note_refund: '', //绑定退款备注
				row_detail: '',//表格行uid 用于单个座位退款
				modal_refund_all: false,//订单退款弹窗
				modal_refund: false,//退款弹窗
				state_color: {
					12: '',
					1: 'warning',
					2: 'primary',
					4: 'error',
					5: 'default',
					6: 'error'
				},//订单状态标签颜色配置
				state: {
					12: "全部", //
					1: '未支付', //黄色    <Tag color="warning">warning</Tag>
					2: "已支付", //蓝色<Tag color="primary">primary</Tag>
					4: "已退款", //红色  <Tag color="error">error</Tag>
					5: "已关闭", //灰色 <Tag color="default">default</Tag>
					6: '部分退款'
				},//订单状态标签文字配置
				order: '', //当前查询订单全部信息
				keyword: '', //绑定订单号
			}
			
		},
		watch:{
			modal_refund(value){
				this.reset()
			},
			modal_refund_all(value){
				this.reset()
			}
		},
		computed: {
			// 格式化输出订单描述 将数组通过空格 粘合成字符串
			orderAbout() {
				return function(orderAbout) {
					return orderAbout.join(' ')
				}
			},
		},
		methods: {
			reset(){
				this.note_refund_all=''
				this.note_refund = ''
			},
			// 退款业务
			async to_refund(type) {
				if (type == '全部退款') {
					if (!this.note_refund_all) {
						this.$mytoast_err('请输入备注信息')
						return
					}
					let pass_data = {
						'retName':JSON.parse(localStorage.getItem('选中品牌1')).user.name,
						"orderid": this.order.orderid, //退款订单号
						"all": true, //全部退款 优先级高于uidList
						"uidList": [], //退款订单内某个商品如果传了all=true 此部分退款无效
						"retNote": this.note_refund_all //退款备注
					}
					
					let res_data = await OrderRefund(pass_data);
					
					if (res_data.code != 200) {
						this.$mytoast(res_data.msg)
						return
					}
					if (res_data.msg == 'ok') {
						this.$mytoast('退款成功')
					}
					this.keyword = res_data.data.orderid
					this.to_GetOrderDatab()
					this.modal_refund_all = false

				}
				if (type == '退款') {
					if (!this.note_refund) {
						this.$mytoast_err('请输入备注信息')
						return
					}
					let pass_data = {
						'retName':JSON.parse(localStorage.getItem('选中品牌1')).user.name,
						"orderid": this.order.orderid, //退款订单号
						"all": false, //全部退款 优先级高于uidList
						"uidList": [this.row_detail], //退款订单内某个商品如果传了all=true 此部分退款无效
						"retNote": this.note_refund //退款备注
					}
					
					let res_data = await OrderRefund(pass_data);

					if (res_data.code != 200) {
						this.$mytoast(res_data.msg)
						return
					}
					if (res_data.msg == 'ok') {
						this.$mytoast('退款成功')
					}
					this.keyword = res_data.data.orderid
					this.to_GetOrderDatab()
					this.modal_refund = false
				}

			},
			
			// 获取订单信息
			async to_GetOrderDatab() {
				
				if (!this.keyword) {
					this.$mytoast_err('请输入订单号查询')
					return
				}
				let pass_data = {
					'keyword': this.keyword
				}
				
				let res_data = await GetOrderDatab(pass_data);
				
				if (res_data.code == 200) {
					let center = JSON.parse(JSON.stringify(res_data.data))
					center.gooslist.forEach((item, index) => {
						center.gooslist[index].title.push({
							key: "operation",
							title: "操作",
							slot: 'operation'
						})
					})
					this.order = center
				} else {
					this.$mytoast_err(res_data.msg)
				}

			}
			
		}
	}
</script>
