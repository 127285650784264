<template>
	<div>
		<Form  :label-width="120">
			<FormItem label="卖品详情"></FormItem>
		</Form>
		<quill-editor
			class="editor" 
			ref="myQuillEditor"
			:value="value" 
			:options="editorOption"
			@change="onEditorChange"  />
		<qiniuUploadBtn  
			ref="qiniu"
			v-show="false"
			:qiniu_config="qiniu_config"
			@set_url="(value)=>handleQiniuTofuwenben(value)" 
			/>
	</div>
</template>

<script>
	import dedent from 'dedent'
	import hljs from 'highlight.js'
	import {
		quillEditor
	} from 'vue-quill-editor'
	// highlight.js style
	import 'highlight.js/styles/tomorrow.css'
	// import theme style
	import 'quill/dist/quill.core.css'
	import 'quill/dist/quill.snow.css'
	import debounce from 'lodash/debounce'
	import qiniuUploadBtn from '@/components/qiniu_upload_btn.vue'
	import {getupTK} from "@/request/api"
 	export default{
		name:"fuwenben",
		components:{
			qiniuUploadBtn	
		},
		data(){
			return{
				qiniu_config: {}, //表单状态数据
				value:"",
				content: dedent``,
				editorOption: {
					modules: {
						toolbar: [
							['bold', 'italic', 'underline', 'strike'],
							['blockquote', 'code-block'],
							[{
								'header': 1
							}, {
								'header': 2
							}],
							[{
								'list': 'ordered'
							}, {
								'list': 'bullet'
							}],
							[{
								'script': 'sub'
							}, {
								'script': 'super'
							}],
							[{
								'indent': '-1'
							}, {
								'indent': '+1'
							}],
							[{
								'direction': 'rtl'
							}],
							[{
								'size': ['small', false, 'large', 'huge']
							}],
							[{
								'header': [1, 2, 3, 4, 5, 6, false]
							}],
							[{
								'font': []
							}],
							[{
								'color': []
							}, {
								'background': []
							}],
							[{
								'align': []
							}],
							['clean'],
							['link', 'image', 'video']
						],
						syntax: {
							highlight: text => hljs.highlightAuto(text).value
						}
					}
				},
			}
		},
		mounted() {
			this.to_getupTK()
			this.$refs.myQuillEditor.quill.getModule('toolbar').addHandler('image', this.imgHandler)
			// this.addRange = this.$refs.myQuillEditor.quill.getSelection()
			// this.$refs.myQuillEditor.quill.insertEmbed(this.addRange !== null ? this.addRange.index : 0, 'image',
			// 	url) // 调用编辑器的 insertEmbed 方法，插入UR
		},
		methods:{
			// 点击图片ICON触发事件
			imgHandler(state) {
				this.addRange = this.$refs.myQuillEditor.quill.getSelection()
				if (state) {
					let fileInput = this.$refs.qiniu.$refs.uploadImg
					fileInput.$el.click() // 加一个触发事件
				}
				this.uploadType = 'image'
			},
			/**
			 * 插入七牛云图片到富文本
			 */
			handleQiniuTofuwenben(value){
				this.value += `<p><img src="${value}"></p>`
			},
			/**
			 * 获取七牛云token
			 */
			async to_getupTK() {
				let { data: res_data } = await getupTK()
				this.qiniu_config = res_data.data
			},
			/**
			 * @param {Object} value 富文本实时内容
			 */
			onEditorChange: debounce(function(value) {
				this.value = value.html
				this.$emit("changevalue",value.html)
			}, 466),
		}
	}
</script>

<style>
	.ql-container.ql-snow{
		height: 300px;
	}
</style>