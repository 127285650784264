<template>
	<div>
		<!-- 操作区域 -->
		<div style="text-align: right;">
			<!-- 请选择消费方式 -->
			<Select placeholder='请选择消费方式' multiple style="width: 300px;text-align: left;" v-model="select_paytype">
				<Option :key='index' :value="index" v-for="(item,index) in paytype">{{item}}</Option>
			</Select>
			<!-- 放映时间 -->
			<DatePicker v-if="start_end_time[0]==''||start_end_time[1]==''||start_end_time==''" placeholder='请选择放映时间'  @on-change='($)=>current_select_date=$'   type="date"  style="margin-left: 20px; width: 200px"></DatePicker>
			<DatePicker v-if="current_select_date==''" placeholder='请选择销售时间区间-'  @on-change='($)=>start_end_time=$'   type="daterange"  style="margin-left: 20px; width: 200px"></DatePicker>
			<Button @click="()=>to_ticketSalesData()" style="margin-left: 10px;" type='primary'>查询</Button>
			<Button @click="()=>exportData()" style="margin-left: 10px;" >导出全部数据</Button>
		</div>
		<!-- 表格主体部分 -->
		<div style="margin-top: 10px;">
			<Table stripe  style="width: 100%" ref="table" :columns='title' :data='list'></Table>
		</div>
		<!-- 表格分页部分 -->
		<Card style="text-align: right;">
			<Page @on-change="($)=>to_ticketSalesData($)"  :page-size='size' :total='total'></Page>
		</Card>
	</div>
</template>

<script>
	
	let mcode;
	import { download } from '@/utils/table_download.js'
	import {ticketSalesData} from '@/request/api.js'
	export default{
		name:'navbar',
		data() {
			return{
				total:0,//当前查询日期的总记录数
				size:0,//当前查询日期的单页最大记录数
				page:'',//当前查询日期的页
				start_end_time:[],//xxxx-xx-xx当前查询销售时间 区间
				current_select_date:'',//当前查询的放映时间 xxxx-xx-xx
				select_paytype:['1','2','3'],//当前查询的支付方式
				paytype:{
					'1':'微信支付',
					'2':'会员卡消费',
					'3':'票券抵扣'
				},//当前查询的支付方式配置项
				list:[],//当前查询时间的单页记录数
				title:[],//当前查询时间的表头
			}
		},
		
		mounted(){
			mcode = localStorage.getItem('mcode')
		},
		methods:{
			
			// 导出全部数据Ajax数据获取
			async exportData(){
				
				if(this.start_end_time==''&&this.current_select_date==''){
					this.$mytoast_err('请选择日期')
					return
				}
				let pass_data = {
					'paytype':JSON.stringify(this.select_paytype),//1微信支付 2会员卡消费 3票券抵扣
					'mcode':mcode,
					'page':1,
					'all':"1",//all此参数如提交则获取全部数据（一般为导出功能使用）
				};
				// 用户选择当前销售时间区间
				if(this.current_select_date==''){
					pass_data["startTime"] = new Date(this.start_end_time[0]).getTime();//销售时间区间-开始（与date 互斥两者提供一个即可）时间戳
					pass_data["endTime"] = new Date(this.start_end_time[1]).getTime();//销售时间区间-结束（与date 互斥两者提供一个即可）时间戳
				}
				// 用户选择放映时间
				if(this.current_select_date!=''){
					pass_data["date"] = this.current_select_date;//放映时间
				}
				
				let {data:res_data} = await ticketSalesData(pass_data)
				
			    download({
				  'worksheet':'电影票销售明细表',
				  headers:res_data.title,
				  data:res_data.list
			    })
				
			},
			
			// 分页功能 和 查询功能AJAX数据获取
			async to_ticketSalesData(page=1){
				
				// 初始化表格数据
				this.title = []
				this.list = []
				// 用户未选择当前日期的放映时间 和 销售时间区间 打回
				if(this.start_end_time==''&&this.current_select_date==''){
					this.$mytoast_err('请选择日期')
					return
				}
				let pass_data = {
					'paytype':JSON.stringify(this.select_paytype),//1微信支付 2会员卡消费 3票券抵扣
					'mcode':mcode,
					'page':page,
				};
				// 用户选择当前销售时间区间
				if(this.current_select_date==''){
					pass_data["startTime"] = new Date(this.start_end_time[0]).getTime();//销售时间区间-开始（与date 互斥两者提供一个即可）时间戳
					pass_data["endTime"] = new Date(this.start_end_time[1]).getTime();//销售时间区间-结束（与date 互斥两者提供一个即可）时间戳
				}
				// 用户选择放映时间
				if(this.current_select_date!=''){
					pass_data["date"] = this.current_select_date;//放映时间
				}
				
				let {data:res_data} = await ticketSalesData(pass_data)
	
				res_data.title.forEach((item,index)=>{
					if(index<=3){
						item['fixed']='left';//给定表头前四项左固定
						item['width']='150';//给定表都前四项宽度150px
					}else{
						item['width']='200';//给定表头前四项往后宽度200px
					}
				})
				this.page=res_data.page;//当前查询时间页码
				this.size=res_data.size;//当前查询时间最大记录数
				this.total=res_data.total;//当前查询时间总记录数
				this.title = res_data.title;//当前查询时间表头
				this.list = res_data.list;//当前查询时间单页数据集
				
			}
			
		}
	}
</script>

