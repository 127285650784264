<template>
	<div>
		<div style="text-align: right;">
			<Button type='primary' @click="()=>activity_create_modal=true">创建活动</Button>
		</div>
		<div style="margin-top: 20px;">
			<Row>
				<Col style="padding: 20px;" v-for="item in active_list" span="8">
				<Card>
					<div style="line-height: 35px;">
						<span style="display: inline-block;width: 130px;">活动名称</span>
						{{item.name}}
					</div>
					<div style="line-height: 35px;">
						<span style="display: inline-block;width: 130px;">门店mcode</span>
						{{item.mcode}}
					</div>
					<div style="line-height: 35px;">
						<span style="display: inline-block;width: 130px;">活动类型</span>
						{{item.createType}}
					</div>
					<div style="line-height: 35px;">
						<span style="display: inline-block;width: 130px;">活动开始时间</span>
						{{item.startTime}}
					</div>
					<div style="line-height: 35px;">
						<span style="display: inline-block;width: 130px;">活动结束时间</span>
						{{item.endTime}}
					</div>
					<div style="line-height: 35px;">
						<span style="display: inline-block;width: 130px;">活动状态</span>
						<i-Switch @on-change='($)=>to_SetState(item)' :true-value="2" :false-value="1"
							v-model="item.state"></i-Switch>
						<!-- {{item.state===1?'待启用':'启用'}} -->
					</div>
					<div style="position: absolute;top: 10px;right: 10px;">
						<Button size='small'
							@click="(()=>(active_edit_modal=true)&&to_GetActivityContent(item))">编辑</Button>
						<Poptip transfer confirm title="确定是否删除?" @on-ok="()=>to_SetState(item,'-1')">
							<div style="display: flex;">
								<Button type="error" style="margin-left: 10px;" size='small'>删除</Button>
							</div>
						</Poptip>

					</div>
					<div style="text-align: right;">
						<Button @click="()=>to_getCodeGenerat(item)" size='small' type="primary"
							v-if="!item.qrcode">获取二维码</Button>
						<Poptip v-else title="Title" placement="top">
							<Button>查看二维码</Button>
							<div slot="title"><i>{{item.name}}</i></div>
							<div slot="content">
								<img style="width: 150px;height: 150px;" :src="item.url+item.qrcode" alt="">
							</div>
						</Poptip>
					</div>

				</Card>
				</Col>
			</Row>
		</div>
		<Modal :closable='false' :mask-closable='false' @on-visible-change="()=>resetFieldsHandle('formItem_edit')"
			width="800" v-model="active_edit_modal">
			<div style="padding: 40px 0 0 0;">
				<Form ref="formItem_edit" :model="formItem_edit" :label-width="130" :rules="ruleItem">
					<div style="width: 300px;">
						<FormItem :label-width="130" label="领取上限">
							<Input v-model="current_edit_row.content.getmax"></Input>
						</FormItem>
						<FormItem :label-width="130" label="限制类型">
							<Select v-model="current_edit_row.content.type">
								<Option v-for="item in types_option" :value="item">{{item}}</Option>
							</Select>
						</FormItem>
						<FormItem v-if="current_edit_row.content.type=='自定义'" :label-width="130" label="自定义时间">
							<DatePicker type="datetime" placeholder="开始时间" v-model="current_edit_row.content.time">
							</DatePicker>
						</FormItem>
					</div>
					<div style="text-align: right;line-height: 50px;">
						<Button @click="()=>to_SetActivityContent()" type="primary">保存</Button>
					</div>
					<Row>
						<Col span="10">
						<FormItem prop="apply" label="活动创建类型">
							<Select @on-select="($)=>(formItem_edit.name=$.label)&&(formItem_edit.apply=$.value)"
								v-model="formItem_edit.apply" style="text-align: left;width: 200px;">
								<Option v-for="item in SelectionGet_option" :value="item.value">{{item.label}}
								</Option>
							</Select>
						</FormItem>
						</Col>
						<Col span="7">
						<FormItem :label-width="100" prop="num" label="赠送套数">
							<Input v-model="formItem_edit.num"></Input>
						</FormItem>
						</Col>
						<Col span="7">
						<FormItem prop="time" :label-width="80" label="有效期">
							<Input v-model="formItem_edit.time"></Input>
						</FormItem>
						</Col>
					</Row>

				</Form>
			</div>
			<div>
				<Alert show-icon>有效期如果为0 则使用票券模板限定有效期，否则则按获得时间+有效期自动生成</Alert>
				<div style="text-align: right;line-height: 50px;">
					<Button type="primary" @click="()=>to_addActivityGivlist()">添加</Button>
				</div>
				<Table v-if="current_edit_row" :columns="current_edit_row.content.title"
					:data="current_edit_row.content.giv">
					<template slot-scope="{row}" slot="operation">
						<Button @click="()=>to_delActivityGivlist(row)" size='small' type="error">删除</Button>
					</template>
				</Table>
			</div>
			<div slot="footer">
				<Button @click="()=>active_edit_modal=false">返回</Button>
			</div>
		</Modal>
		<Modal :closable='false' :mask-closable='false' @on-visible-change="()=>resetFieldsHandle('formItem')"
			v-model="activity_create_modal">
			<div style="max-width: 430px;">
				<Form ref="formItem" :model="formItem" :label-width="130" :rules="ruleItem">
					<FormItem prop="name" label="活动名称">
						<Input v-model="formItem.name"></Input>
					</FormItem>
					<FormItem prop="createType" label="活动创建类型">
						<Select v-model="formItem.createType">
							<Option v-for="item in createType_option" :value="item">{{item}}</Option>
						</Select>
					</FormItem>
					<FormItem prop="mcode" v-if="formItem.createType=='品牌活动'" label="门店">
						<Select multiple v-model="formItem.mcode">
							<Option v-for="item in holdStores_option.stores" :value="item.mcode">{{item.cname}}
							</Option>
						</Select>
					</FormItem>
					<FormItem prop="name" label="活动时间">
						<Row>
							<Col span="11">
							<DatePicker type="datetime" placeholder="开始时间" v-model="formItem.startTime">
							</DatePicker>
							</Col>
							<Col span="2" style="text-align: center">-</Col>
							<Col span="11">
							<DatePicker type="datetime" placeholder="结束时间" v-model="formItem.endTime"></DatePicker>
							</Col>
						</Row>
					</FormItem>
					<FormItem prop="type" label="活动类型">
						<Select disabled v-model="formItem.type">
							<Option v-for="item in type_option" :value="item">{{item}}</Option>
						</Select>
					</FormItem>
				</Form>
			</div>
			<div slot="footer">
				<Button @click="()=>activity_create_modal=false">取消</Button>
				<Button @click="()=>to_ActivityCreate()" type="primary">确认</Button>
			</div>
		</Modal>
	</div>
</template>

<script>
	import {
		SetState,
		ActivityCreate,
		Get_holdStores,
		ActivityGetList,
		SelectionGet,
		GetActivityContent,
		addActivityGivlist,
		delActivityGivlist,
		SetActivityContent,
		getCodeGenerat
	} from '@/request/api'
	export default {
		name: "activity_codes",
		data() {
			return {
				types_option: ['每天', '自定义'],
				current_edit_row: {
					content: {
						getmax: "",
						type: "",
						time: ""
					}
				},
				SelectionGet_option: "",
				formItem_edit: {
					apply: "",
					name: "",
					time: "",
					num: "",
				},
				active_edit_modal: false,
				active_list: [],
				holdStores_option: [],
				ruleItem: {
					apply: {
						required: true,
						trigger: 'blur'
					},
					num: {
						required: true,
						trigger: 'blur'
					},
					time: {
						required: true,
						trigger: 'blur'
					},
					name: {
						required: true,
						trigger: 'blur'
					},
					createType: {
						required: true,
						trigger: 'blur'
					},
					startTime: {
						required: true,
						trigger: 'blur'
					},
					endTime: {
						required: true,
						trigger: 'blur'
					},
					type: {
						required: true,
						trigger: 'blur'
					},
				},
				term_option: [{
					name: "大于或等于",
					value: "$gte"
				}, {
					name: "等于",
					value: "$ne"
				}],
				type_option: ['营销活动', '提成策略', '首充活动', '扫码有礼'],
				createType_option: ['品牌活动', '门店活动'],
				formItem: {
					mcode: [],
					"name": "", //活动名称
					"createType": "", //活动创建类型 品牌创建 | 门店创建
					"startTime": "", //活动开始时间，13位时间戳
					"endTime": "", //活动结束时间，13位时间戳
					"type": "扫码有礼", //活动类型 营销活动 | 提成策略 | 首充活动 | 扫码有礼
				},
				activity_create_modal: false
			}
		},
		created() {
			this.to_Get_holdStores()
			this.to_ActivityGetList()
			this.to_SelectionGet()
		},
		methods: {
			async to_getCodeGenerat(row) {
				await getCodeGenerat(row)
				this.to_ActivityGetList()
			},
			async to_SetState(row, type) {
				// let pass_data=this
				if (type == -1) {
					row['state'] = type
				}
				await SetState(row)
				this.to_ActivityGetList()
			},
			async to_SetActivityContent() {

				let pass_data = this.current_edit_row
				if (pass_data['content']['type'] == '每天') {
					pass_data['content']['time'] = ""
				}
				pass_data['content']['time'] = new Date(pass_data['content']['time']).getTime()
				await SetActivityContent(pass_data)
				console.log(11111)
				this.to_GetActivityContent(this.current_edit_row)
			},
			async to_delActivityGivlist(row) {
				row["uid"] = this.current_edit_row.uid
				await delActivityGivlist(row)
				this.to_GetActivityContent(this.current_edit_row)
			},
			async to_addActivityGivlist() {
				this.$refs.formItem_edit.validate(async (valid) => {
					if (valid) {
						let {
							createType,
							num,
							time
						} = this.formItem
						let pass_data = this.formItem_edit
						pass_data['uid'] = this.current_edit_row.uid
						await addActivityGivlist(pass_data)
						this.to_GetActivityContent(this.current_edit_row)
					}
				})


			},
			async to_GetActivityContent(row) {
				let pass_data = {
					"uid": row.uid
				}
				let {
					data: res_data
				} = await GetActivityContent(pass_data)
				res_data[0].content.title.push({
					"key": "operation",
					"title": "操作",
					"slot": "operation"
				})
				this.current_edit_row = res_data[0]
			},
			async to_SelectionGet() {
				let pass_data = {
					type: "活动票券",
					mcode: localStorage.getItem('mcode'),
					branduid: JSON.parse(localStorage.getItem('选中品牌1')).brand.uid
				}
				let {
					data: res_data
				} = await SelectionGet(pass_data)
				this.SelectionGet_option = res_data
			},
			async to_ActivityGetList() {
				let pass_data = {
					branduid: JSON.parse(localStorage.getItem('选中品牌1')).brand.uid,
					mcode: localStorage.getItem('mcode'),
					type: "扫码有礼"
				}
				let {
					data: res_data
				} = await ActivityGetList(pass_data)
				this.active_list = res_data
			},
			resetFieldsHandle(name) {
				this.$nextTick(() => {
					this.$refs[name].resetFields()
				})
			},
			async to_Get_holdStores() {
				let {
					data: res_data
				} = await Get_holdStores()
				this.holdStores_option = res_data.hold_list[0]
			},
			async to_ActivityCreate() {
				this.$refs.formItem.validate(async (valid) => {
					if (valid) {
						let pass_data = JSON.parse(JSON.stringify(this.formItem))
						if (pass_data.createType == '门店活动') {
							pass_data.mcode = [localStorage.getItem('mcode')]
						}
						pass_data['startTime'] = new Date(pass_data['startTime']).getTime()
						pass_data['endTime'] = new Date(pass_data['endTime']).getTime()
						pass_data['branduid'] = this.holdStores_option.branduid
						let res = await ActivityCreate(pass_data)
						if (res.code != 200) {
							return
						}
						this.activity_create_modal = false
						this.to_ActivityGetList()
					}
				})
			}
		}
	}
</script>

<style>
</style>
