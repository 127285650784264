<template>
	<div>
		<div style="text-align: right;">
			<Button @click="()=>Addapolicy()" type='primary'> 添加策略</Button>
		</div>
		<div style="margin-top: 10px;">
			<Table :columns='title' :data='list'>
				<template slot-scope='{row,column,data}' slot="available">
					<Tag type='primary' v-if="row[column['key']]"> <span style="color: #fff;">启用</span></Tag>
					<Tag v-if="!row[column['key']]">未启用</Tag>
				</template>
				<template slot-scope='{row,column,data}' slot="operation">
					<div>
						<Poptip transfer confirm title="确定是否删除?" @on-ok="()=>to_SetStrategy(row)">
							<div style="display: flex;">
								<Button style="margin-right: 10px;" type='error' size='small'>删除</Button>
							</div>
						</Poptip>
						<Button @click="()=>to_SetStrategy(row,'启用')" size='small'
							v-if="(!row.available)&&($my_checkBtn('enable_strategy'))">启用</Button>
						<Button @click="()=>to_SetStrategy(row,'禁用')" size='small'
							v-if="(row.available)&&($my_checkBtn('disable_strategy'))">禁用</Button>
					</div>
				</template>
			</Table>
		</div>
		<!-- 对话框 -->
		<Modal width="600" title='添加策略' :closable='false' :mask-closable='false' v-model='is_show_moidal_add_policy'>
			<Form ref="form" :model="strategy"  :label-width="100">
				<FormItem prop="type"  label="策略类型">
					<RadioGroup v-model="strategy.type">
						<Radio label='基本策略'></Radio>
						<Radio label='附加策略'></Radio>
					</RadioGroup>
				</FormItem>
				<FormItem prop="name" label="策略名称">
					<Input v-model="strategy.name" style="width: 300px;"></Input>
				</FormItem>
				<div v-if="strategy.type=='附加策略'">
					<FormItem prop="nkey" label="命中条件">
						<RadioGroup v-model="strategy.nkey">
							<Radio label='按日期'></Radio>
							<Radio label='按影片'></Radio>
							<Radio label='按影厅'></Radio>
							<Radio label='按时段'></Radio>
						</RadioGroup>
					</FormItem>
					<FormItem prop="val"  v-if="strategy.nkey=='按日期'" label="日期">
						<DatePicker @on-change="(value)=>strategy.val=value" type="daterange" format="yyyy-MM-dd" placeholder="选择时间"
							style="width: 300px"></DatePicker>
					</FormItem>
					<FormItem prop="val" v-if="strategy.nkey=='按影片'" label="影片名">
						<Select placeholder="请输入影片名" style="width: 300px;" v-model="strategy.val" multiple filterable
							:remote-method="remoteMethod4" :loading="loading4"
							@on-set-default-options="setDefaultOptions">
							<Option v-for="(option, index) in options4" :value="option.value" :key="index">
								{{option.label}}
							</Option>
						</Select>
					</FormItem>
					<FormItem  prop="val" v-if="strategy.nkey=='按影厅'" label="影厅名">
						<Select multiple v-model="strategy.val" placeholder="请选择影厅" style="width: 300px;">
							<Option v-for="item in ting_list" :value="item.value" :key='item.label'>{{item.value}}
							</Option>
						</Select>
					</FormItem>
					<FormItem prop="val" v-if="strategy.nkey=='按时段'" label="时段">
						<TimePicker type="timerange" v-model="strategy.val" placement="bottom-end" style="width: 168px"></TimePicker>
					</FormItem>
				</div>
				<FormItem prop="val" v-if="strategy.type=='基本策略'" label="定价时间">
					<Select v-model='strategy.val' style="width: 300px;" multiple>
						<Option v-for="item in week" :value='item'>{{item}}</Option>
					</Select>
				</FormItem>
				<div style="display: flex;">
					<FormItem prop="baseSellPrice" label="基本价格">
						<Input :disabled="strategy.systemPrice" v-model="strategy.baseSellPrice" style="width: 300px;margin-right: 10px;" type='number'></Input>
					</FormItem>
					<FormItem prop="systemPrice" label="按系统价格">
						 <Checkbox @on-change="()=>resetPrice()" v-model="strategy.systemPrice"></Checkbox>
					</FormItem>
				</div>
				<div style="display: flex;">
					<FormItem prop="MemberPrice" label="会员价格">
						<Input :disabled="strategy.issuePrice" v-model="strategy.MemberPrice" style="width: 300px;margin-right: 10px;" type='number'></Input>
					</FormItem>
					<FormItem prop="issuePrice" label="按最低发行价">
						<Checkbox @on-change="()=>resetPrice()" v-model="strategy.issuePrice"></Checkbox>
					</FormItem>
				</div>
			</Form>
			<div slot='footer'>
				<Button @click="()=>is_show_moidal_add_policy=false">返回</Button>
				<Button @click="()=>to_addStrategy()" type='primary'>确定</Button>
			</div>
		</Modal>
	</div>
</template>

<script>
	import {
		GteScreensList,
		addStrategy,
		GetStrategyList,
		SetStrategy,
		Gterecenttitle
	} from '@/request/api.js'
	export default {
		name: 'navbar',
		data() {
			return {
				ting_list: [], //影厅名下拉菜单
				list2: [], //影片列表
				loading4: false,
				date: '',
				hitting_condition: '按日期',
				list: [],
				title: [],
				week: ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
				Radio_select: '基本策略',
				is_show_moidal_add_policy: false, //对话框 添加策略
				strategyCopy:{},
				strategy:{
					"name":"",
					"type":"基本策略",
					"modifier":JSON.parse(localStorage.getItem('选中品牌1')).user.name,
					"val":[],
					"issuePrice":false,
					"systemPrice":false,
					"baseSellPrice":"",
					"MemberPrice":"",
					"nkey":"按日期"
				}
			}
		},
		watch: {
			is_show_moidal_add_policy(value){
				if(!value){
					this.handleReset("form")
				}
			},
			async ["strategy.nkey"](val) {
				this.strategy.val=[]
				if (val == "按影片") {
					let res_data = await Gterecenttitle()
					this.list2 = res_data.data
				}
				if (val == "按影厅") {
					let res_data = await GteScreensList()
					this.ting_list = res_data.data
				}
			}
		},
		created() {
			// this.strategyCopy = JSON.parse(JSON.stringify(this.strategy))
		},
		mounted() {
			this.flash()
		},
		methods: {
			handleReset (name) {
				this.$refs[name].resetFields();
			},
			/**
			 * 价格初始化
			 */
			resetPrice(){
				this.strategy.MemberPrice =""
				this.strategy.baseSellPrice =""
			},
			Addapolicy(){
				// this.strategy = JSON.parse(JSON.stringify(this.strategyCopy))
				this.is_show_moidal_add_policy=true
			},
			setDefaultOptions(options) {
				this.options4 = options;
			},
			remoteMethod4(query) {
				if (query !== '') {
					this.loading4 = true;
					setTimeout(() => {
						this.loading4 = false;
						this.options4 = this.list2.filter(item => item.label.indexOf(query) > -1);
					}, 200);
				} else {
					this.options4 = [];
				}
			},
			async to_SetStrategy(row, type = '删除') {
				let pass_data = {
					"uid": row.uid,
					"state": type
				}
				let res_data = await SetStrategy(pass_data);
				if (res_data.code == 200) {
					this.$mytoast(res_data.msg)
					this.flash()
				}
			},
			async flash() {
				let {
					data: res_data
				} = await GetStrategyList()
				res_data.title.forEach(item => {
					if (item.key == "available") {
						item.slot = "available"
					}
				})
				res_data.title.push({
					'title': '操作',
					'key': 'operation',
					'slot': 'operation'
				})
				this.list = res_data.list
				this.title = res_data.title
			},
			async to_addStrategy() {
				if(this.strategy.type=='基本策略') delete this.strategy.nkey
				let res_data = await addStrategy(this.strategy)
				if (res_data.code == 200) {
					this.$mytoast(res_data.msg)
					this.flash()
					this.is_show_moidal_add_policy = false
				}
			}
		},
	}
</script>
