<template>
	<div>
		<div class="selected">
			<div>已选影院：百阅国际影城（寮步景泰时代店）</div>
			<div>影厅数：7</div>
			<div>座位总数：602</div>
		</div>
		<div class="edit">
			<DatePicker @on-change="handleDateChange" :value="paipianDate" type="date" placeholder="选择日期"
				style="width: 200px"></DatePicker>
			<div> <Button @click="()=>addModal=true" type="primary">编辑</Button></div>
		</div>



		<!-- 影厅事件 -->
		<div style="display: flex;">
			<div>
				<div>
					<div style="width: 200px;
		line-height: 30px;
		padding: 10px;
		box-sizing: border-box;">影厅</div>
				</div>
				<div style="display: flex;height: 62px;border-bottom: 1px solid #ccc;" v-for="item in 10">
					<div style="width: 200px;
		background-color: #d8e2ed;
		padding: 10px;">
						<div style="text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;">1号厅（会员购票哦阿斯顿发送到发送到发的 阿斯顿发阿斯顿发）</div>
						<div>3D影厅｜142座</div>
					</div>
				</div>
			</div>

			<div style="overflow-x: scroll;flex: 1;">
				<div style="position: relative;height: 50px;border-top: 1px solid;width: 2880px;">
					<div v-for="(item,index) in 24" :style="
						`left: ${index*120}px;width: 1px;
						height: 20px;
						background-color: #ccc;
						position: absolute;`">
						<div style="
							left: 30px;width: 1px;
							height: 10px;
							background-color: #000;
							position: absolute;"></div>
						<div style="
							left: 60px;width: 1px;
							height: 10px;
							background-color: #000;
							position: absolute;"></div>
						<div style="
							left: 90px;width: 1px;
							height: 10px;
							background-color: #000;
							position: absolute;"></div>
						<div style="left: -3px;top: 16px;position: absolute;">{{chiIndex[index]}}</div>
					</div>
				</div>
				<!-- 1分钟 = 2px -->
				<div v-for="item in 10" style="border-bottom: 1px solid #ccc;height: 62px;position: relative;width: 2880px;">
					<div :style="`left:${120*1}px;flex: 1;position: relative;height: 61px;position: absolute;cursor: pointer;`">
						<div style="
							width: 100px;
							height: 100%;
							background-color: #fff691;
							flex-direction: column;
							justify-content: space-between;
							display: flex;
							padding: 5px;
							font-size: 12px;
							justify-content: space-around;">
							<div style="
								text-overflow: ellipsis;
								white-space: nowrap;
								
								overflow: hidden;">独行月球阿斯顿发送到发送到发送到发送到发 </div>
							<div>11:00-13:00</div>
						</div>
					</div>
				</div>
			</div>

		</div>
		<Modal v-model="addModal">
			<div>asdf</div>
			<div slot="footer">
				<Button @click="()=>addModal=false">取消</Button>
				<Button type="primary">确定</Button>
			</div>
		</Modal>

	</div>
</template>

<script>
	export default {
		data() {
			return {
				addModal:false,
				chiIndex: [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 0, 1, 2, 3, 4, 5, 6],
				paipianDate: "",
			}
		},
		mounted() {
				document.onkeydown=function(e){
					var keyNum = window.event?e.keyCode:e.which
					if(keyNum==37){
						console.log('左')
					}
					if(keyNum==38){
						console.log('上')
					}
					if(keyNum==39){
						console.log('右')
					}
					if(keyNum==40){
						console.log('下')
					}
				}
		},
		methods: {
			handleDateChange(date) {
				this.paipianDate = date;
			}
		}
	}
</script>

<style scoped lang="less">
	.selected {
		display: flex;
		line-height: 50px;
		background-color: #f2f2f2;
	}

	.edit {
		display: flex;
		justify-content: space-between;
		align-items: center;
		line-height: 100px;
	}

</style>
