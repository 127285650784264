<template>
	<div>
		<div style="text-align: right;margin-bottom: 10px;">
			<Button @click="()=>table_dowload_card('同步数据')" type="primary">同步数据</Button>
			<Button  @click="()=>table_dowload_card()" style="margin-left: 10px;">导出数据</Button>
		</div>
		<Table :columns="title" :data="list">
			<template slot-scope="{row}" slot="operation" >
				<Poptip transfer confirm title="确定是否重制密码?" @on-ok="()=>table_dowload_card('重置密码',row.uid)">
					<div style="display: flex;">
						<Button>重置密码</Button>
					</div>
				</Poptip>
			</template>
			<template slot-scope="{row}" slot="isauth" >
				<i-Switch @on-change="()=>table_dowload_card('修改授权',row.uid)" v-model="row.isauth"></i-Switch>
			</template>
			<template slot-scope="{row}" slot="state" >
				<i-Switch @on-change="()=>table_dowload_card('修改登录',row.uid)" v-model="row.state"></i-Switch>
			</template>
		</Table>
		 <Card style="text-align: right;">
			 <Page @on-change="($)=>fetchData($)" :total="total" :pageSize="pageSize"/>
		 </Card>
	</div>
</template>

<script>
	import { download } from '@/utils/table_download.js'
	import {PayUser} from "@/request/api" 
	export default{
		name:"account",
		data(){
			return{
				list:[],
				title:[],
				total:0,
				pageSize:0
			}
		},
		async created() {
			this.fetchData()
		
		},
		methods:{
			
		   async table_dowload_card(type="获取数据",uid){
				let config =['修改登录','修改授权','修改密码']
				let pass_data={
					uid,
					"type": type, //['获取数据','同步数据','修改登录','修改授权','重置密码','修改密码']
					"all": true, //是否全部导出【获取数据】
				}
				let res =  await PayUser(pass_data)
				if(res.errcode!=0) return
				if(config.indexOf(type)!=-1){
					this.$mytoast("操作成功")
				}
				if(type=="获取数据"){
					let headers = res.data.title
					let data  = res.data.list
					download({
					  'worksheet':'营业账号',
					  headers,
					  data
					})
				}
				if(type=='同步数据'){
					this.fetchData()
				}
				
				
			},
		   
		   async fetchData(page=1){
				let pass_data={
					"type": "获取数据", //['获取数据','同步数据','修改登录','修改授权','重置密码','修改密码']
					"page":page, //分页页码，数字类型【获取数据】
					"all": false, //是否全部导出【获取数据】
				}
				let res =  await PayUser(pass_data)
				res.data.title.push({
					key: "operation",
					title: "操作",
					slot:"operation"
				})
				res.data.title.forEach(item=>{
					if(item.key=="isauth"){
						item.slot="isauth"
					}
					if(item.key=="state"){
						item.slot="state"
					}
				})
				this.list=res.data.list
				this.title=res.data.title
				this.total=res.data.total
				this.pageSize=res.data.limit
				
			},
			
		}
		
	}
</script>

<style>
</style>