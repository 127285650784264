<template>
	<div>
		<div>
			<!-- 订单信息展示 -->
			<h1>片库管理</h1>
			<div style="text-align: right;">
				<Select style="width: 150px;text-align: left;" v-model="keyword_type">
					<Option v-for="item in cityList" :value="item.value" :key="item.value">{{ item.label }}</Option>
				</Select>
				<Input :disabled="keywordDisabled" v-model="keyword_search" style="width: 300px;margin: 0 10px;"></Input>
				<Button @click="()=>do_keyword_search()" style="margin-left: 10px;" type="primary">搜索</Button>
			</div>
			<!-- 表格部分 -->
			<div>
				<!-- 表格主体部分 -->
				<Table @on-cell-click="(payload)=>current_item=payload" ref="table" stripe style="margin-top: 50px;"
					:columns="columns1" :data="order_data">
					<!-- 最低发行价设置日志 -->
					<template slot-scope="{ row, index }" slot="min_price_log">
						<!-- 判断标签逻辑显示部分 -->
						<div v-html="set_low_price(row.min_price_log)"></div>
					</template>
					<!-- 图片显示 -->
					<template slot-scope="{ row, index }" slot="imgurl">
						<!-- 判断标签逻辑显示部分 -->
						<div style="width: 100px;height: 100px;padding: 10px;">
							<img alt="无数据" style="width: 100%;height: 100%;" :src="row.imgurl">
						</div>
					</template>
					<!-- 操作区域-->
					<template slot-scope="{ row, index }" slot="operation">
						<div style="display: flex;justify-content: space-between;">
							<Button size='small'  @click="()=>Settheprice(row)" type="error">设置发行价</Button>
							<div v-if='row.imgurl'></div>
							<Button style="margin-left: 10px;" size='small' v-if='!row.imgurl' @click="()=>get_cms_uplibraries(row)"
								type="primary">同步数据</Button>
						</div>
					</template>
				</Table>
				<!-- 表格分页部分 -->
				<div style="background-color: white;height: 50px;display: flex;justify-content: flex-end;align-items: center;padding-right: 50px;">
					<Page ref='page' :page-size='size' :total="total" @on-change='on_change_page' />
				</div>
			</div>
		</div>
		<Modal :closable='false' :mask-closable='false' title="设置发行价" v-model="modal10" class-name="vertical-center-modal">
			<Form :label-width="80">
				<FormItem label="设置价格:">
					<Input style="width: 300px;" v-model="set_price_price" type="number"></Input>
				</FormItem>
				<FormItem label="设置日期:">
					<DatePicker @on-change='(pass_data)=>set_price_time =new Date(pass_data).getTime()' type="date" style="width: 200px"></DatePicker>
				</FormItem>
			</Form>
			<div slot="footer">
				<Button @click="()=>modal10=false">取消</Button>
				<Button @click="do_cms_addMinprice" type="primary">确认</Button>
			</div>
		</Modal>
	</div>
</template>

<script>
	
	import {
		cms_addMinprice,
		cms_libraries,
		cms_uplibraries
	} from '@/request/api.js'
	export default {
		name: 'navbar',
		data() {
			return {
				keywordDisabled:true,
				cityList: [{
						value: '全部',
						label: '全部'
					},
					{
						value: '片名',
						label: '片名'
					},
					{
						value: '发行价',
						label: '发行价'
					}, {
						value: '仅搜索无数据影片',
						label: '仅搜索无数据影片'
					},
				],
				keyword_type: "",
				keyword_search: '',
				set_price_price: '', //设置发行价价格
				set_price_time: '', //设置发行价日期
				current_item: {}, //当前选中的影片
				modal10: false,
				total: 0,
				currentPage: 1,
				page: 1,
				size: 30,
				order_data: [],
				columns1: [],
			}
		},
		computed: {
			set_low_price() {
				return function(min_price_logs) {
					return min_price_logs.join('<br/>')
				}
			}
		},
		
		async mounted() {
			
			this.keyword_search = ''
			this.keyword_type = "仅搜索无数据影片";
			let pass_data = {
				'movie': true
			}

			let {
				data: res_data
			} = await cms_libraries(pass_data)

			res_data.title.forEach(item => {
				if (item.key == 'imgurl') {
					// 表格行添加插槽
					item['slot'] = 'imgurl'
				}
				if (item.key == 'min_price_log') {
					item['slot'] = "min_price_log"
				}
			})
			this.order_data = res_data.list
			this.columns1 = res_data.title
			this.size = res_data.size
			this.total = res_data.total
			this.page = res_data.page
			this.columns1.push({
				title: '操作',
				key: 'operation',
				slot: 'operation',
				width:200
			})

		},
		
		watch: {
			
			keyword_type(val) {
				this.keywordDisabled = false
				if (val == '全部' || val == "仅搜索无数据影片") {
					this.keywordDisabled = true
				}
			}
			
		},
		methods: {
			Settheprice(row){
				 this.set_price_price = row.min_price
				 this.modal10=true
			},
			async do_keyword_search() {

				if (this.keyword_search == '' && this.keyword_type != '仅搜索无数据影片' && this.keyword_type != '全部') {
					this.$Message.error('请填写搜索信息')
					return
				}
				this.order_data = ''
				let pass_data
				if (this.keyword_type == '全部') {
					pass_data = ''

				}
				if (this.keyword_type == '仅搜索无数据影片') {
					this.keyword_search = ''
					pass_data = {
						'movie': true
					}
				}
				if (this.keyword_type == '片名') {
					pass_data = {
						'movieName': this.keyword_search.trim()
					}
				}
				if (this.keyword_type == '发行价') {
					pass_data = {
						'min_price': this.keyword_search.trim()
					}
				}
				
				let {
					data: res_data
				} = await cms_libraries(pass_data)

				res_data.title.forEach(item => {
					if (item.key == 'imgurl') {
						// 表格行添加插槽
						item['slot'] = 'imgurl'
					}
					if (item.key == 'min_price_log') {
						item['slot'] = "min_price_log"
					}
				})
				this.order_data = res_data.list
				this.columns1 = res_data.title
				this.size = res_data.size
				this.total = res_data.total
				this.page = res_data.page
				this.columns1.push({
					title: '操作',
					key: 'operation',
					slot: 'operation'
				})

			},
			
			async on_change_page(val) {

				this.order_data = ''
				
				let { data: res_data } = await cms_libraries({
					'page': val
				})

				res_data.title.forEach(item => {
					if (item.key == 'imgurl') {
						// 表格行添加插槽
						item['slot'] = 'imgurl'
					}
					if (item.key == 'min_price_log') {
						item['slot'] = "min_price_log"
					}
				})
				this.order_data = res_data.list
				this.columns1 = res_data.title
				this.size = res_data.size
				this.total = res_data.total
				this.page = res_data.page
				this.columns1.push({
					title: '操作',
					key: 'operation',
					slot: 'operation'
				})
				
			},

			// 设置发行价
			async do_cms_addMinprice() {
				
				if (this.set_price_price == '' || this.set_price_time == '') {
					this.$mytoast_err('请正确调谐价格和日期')
					return
				}
				let pass_data = {
					"movieuid": this.current_item.movieuid, //影片uid
					"amt": this.set_price_price, //设置价格
					"Time": this.set_price_time //生效时间
				}

				let { data: res_data } = await cms_addMinprice({
					...pass_data
				});

				this.modal10 = false
				let center = JSON.parse(JSON.stringify(this.order_data))
				center = center.map(item => {
					if (item.movieuid == res_data.movieuid) {
						return res_data
					}
					return item;
				})
				this.order_data = ''
				this.order_data = center

			},

			// 同步数据
			async get_cms_uplibraries(row) {
				
				let pass_data = row.movieuid
				let { data: res_data } = await cms_uplibraries({
					'movieuid': pass_data
				})
				if (res_data.code == 500) {
					this.$Message.error(res_data.msg)
					return
				}
				let center = JSON.parse(JSON.stringify(this.order_data))
				center.forEach(item => {
					if (item.movieuid == row.movieuid) {
						// 写入丢失的图片
						item['imgurl'] = res_data.imgurl
					}
				})
				this.order_data = ''
				this.order_data = center

			}
			
		}

	}
</script>
