<template>
	<div>
		<div style="width: 800px;">
			<Card v-for="(item,index) in settings">
				<Form :label-width="120">
					<FormItem label="门店名称(全称):">
						<Input disabled :value="item.stores.name" style="width: 300px;"></Input>
					</FormItem>
					<FormItem label="门店简称:">
						<Input v-model="item.stores.asName" style="width: 300px;"></Input>
					</FormItem>
					<FormItem label="状态:">
						<Tag v-if='item.stores.buff===0'>审核中</Tag>
						<Tag color="primary" v-if='item.stores.buff===1 '>生效</Tag>
						<Tag color="error" v-if='item.stores.buff===-1'>审核不通</Tag>
					</FormItem>
					<FormItem label="影院专资编码:">
						<span>{{item.stores.mcode}}</span>
					</FormItem>
					<FormItem label="门店服务器路径:">
						<Input style="width:300px" v-model="item.stores.local"></Input>
					</FormItem>
					<FormItem label="门店客服电话:">
						<Input style="width: 300px;" v-model='item.stores.phone'></Input>
					</FormItem>
					<FormItem label="门店头像:">
						<div>
							<div style="display: flex;align-items: center;">
								<img alt="无数据" style="width: 100px;height: 100px;margin-right: 10px;" :src="item.stores.address">
								<qiniuUploadBtn  :qiniu_config="qiniu_config"
									@set_url="($)=>settings[index].stores.address=$" />
								
							</div>
							<div>
								<Input :disabled="!touxiang" style="width: 300px;margin: 10px;" v-model="item.stores.address"></Input>
								<Checkbox  v-model="touxiang">自定义</Checkbox>
							</div>
							
						</div>
					</FormItem>
					<FormItem label="弹框图片:">
						<div>
							<div style="display: flex;align-items: center;">
								<img alt="无数据" style="width: 100px;height: 100px;margin-right: 10px;" :src="item.stores.src">
								<qiniuUploadBtn  :qiniu_config="qiniu_config"
									@set_url="($)=>settings[index].stores.src=$" />
							</div>
							<div>
								<Input :disabled="!tanchuang" style="width: 300px;margin: 10px;" v-model="item.stores.src"></Input>
								<Checkbox v-model="tanchuang">自定义</Checkbox>
							</div>
						</div>
					</FormItem>
					<FormItem label="弹框跳转路径:">
						<Input v-model="item.stores.advert_navigate_url" style="width: 300px;"></Input>
					</FormItem>
					<FormItem label="弹框是否显示:">
						<i-Switch v-model="item.stores.BoxShows"></i-Switch>
					</FormItem>
					<FormItem label="提示文字+颜色:">
						<div>
							<div>
								<Tag @on-close="()=>Removingtextprompt(item1,index)"
									v-for="(item1,index1) in settings[index].stores.tips" type="border" closable
									:color="item1.colors">
									{{item1.value}}
								</Tag>
							</div>
							<Input v-model="color_title" style="width: 200px;" placeholder="请输入文字提示"></Input>
							<Select v-model="model_color_select" style="width: 100px;margin-left: 10px;">
								<Option :value="item.value" :key="item.value" v-for="item in color_list">{{item.label}}
								</Option>
							</Select>
							<Button @click="()=>add_color_tag(index)" style="margin-left: 10px;">提交</Button>
						</div>
					</FormItem>
					<FormItem label="观影小提示:">
						<div style="display: flex;">
							<div>
								<Input v-model="item.stores.view_tips" type="textarea" :rows="5"
									style="width: 300px;"></Input>
							</div>
							<div>
								<span style="display: flex;align-items: center;margin-left: 10px;">
									<i-Switch style='margin-right: 10px;' v-model='item.stores.is_show_view_tips'>
									</i-Switch>
									<span>是否显示</span>
								</span>
							</div>
						</div>
					</FormItem>
				</Form>
				<div style="text-align: right;margin-top: 20px;">
					<Button @click="()=>do_Set_Store(item)" type='primary'>提交修改</Button>
					<Button style="margin-left: 10px;" @click="()=>do_PushStore(index)" type='error'>同步到小程序</Button>
				</div>
			</Card>
		</div>
	</div>
</template>

<script>
	import {
		Get_StoreDate,
		Set_Store,
		PushStore,
		getupTK
	} from '@/request/api.js'
	import qiniuUploadBtn from '@/components/qiniu_upload_btn.vue'
	export default {
		name: 'navbar',
		components: {
			qiniuUploadBtn
		},
		data() {
			return {
				touxiang:false,
				tanchuang:false,
				qiniu_config: {}, //表单状态数据
				qj: {
					asdf: true
				},
				settings: [], //门店设置
				select_colors: [], //选中的备注标签颜色
				color_title: '', //提示文字
				model_color_select: '',
				color_list: [{
						value: 'red',
						label: '红'
					},
					{
						value: 'yellow',
						label: '黄'
					},
					{
						value: 'blue',
						label: '蓝'
					},
					{
						value: 'green',
						label: '绿'
					},
					{
						value: 'gray',
						label: '灰'
					}
				]
			}
		},
		async mounted() {
			this.to_getupTK()
			await this.flash_data()
		},
		methods: {
			// 获取七牛云token
			async to_getupTK() {
				let {
					data: res_data
				} = await getupTK()
				this.qiniu_config = res_data.data
			},
			Removingtextprompt(value,index){
				let tips = this.settings[index].stores.tips
				tips = tips.filter(item=>value.value!=item.value)
				this.settings[index].stores.tips = tips
			},
			// 首次刷新数据
			async flash_data() {
				this.settings = ''
				let pass_data = {
					mcode: localStorage.getItem('mcode')
				}
				let res_data = await Get_StoreDate(pass_data)
				this.settings = res_data.data
			},
			// 执行添加标签提示
			add_color_tag(index) {
				if (this.model_color_select == '' && this.color_title == '') {
					this.$mytoast_err('请正确填写')
					return
				}
				// push标签到数组
				this.settings[index].stores.tips.push({
					'colors': this.model_color_select,
					'value': this.color_title
				})
			},
			// 提交修改按钮点击事件
			async do_Set_Store(item) {
				let pass_data = {
					"uid": item.stores.uid, //门店uid
					"asName": item.stores.asName, //门店简称
					"phone": item.stores.phone, //门店电话
					"address": item.stores.address, //文店头像
					"src": item.stores.src, //弹框图片
					"advert_navigate_url": item.stores.advert_navigate_url, //弹框地址
					"BoxShows": item.stores.BoxShows, //是否弹框
					"tips": item.stores.tips, //提示标签
					'view_tips': item.stores.view_tips,
					'is_show_view_tips': item.stores.is_show_view_tips
				}
				let res_data = await Set_Store(pass_data)
				if (res_data.code == 200) {
					this.flash_data()
					this.$mytoast('修改成功')
				} else {
					this.$mytoast_err('修改失败')
				}
			},

			// 同步到小程序点击事件
			async do_PushStore(index) {
				// 推送门店数据到小程序
				let pass_data = {
					"branduid": this.settings[index].uid, //品牌uid
					"mcode": localStorage.getItem('mcode') //门店mcode编码
				}
				let res_data = await PushStore(pass_data)
				if (res_data.code == 200) {
					this.$mytoast(res_data.data.resultsOf)
				} else {
					this.$mytoast_err(res_data.msg)
				}
			}
		}
	}
</script>
<style>
	.ivu-form-item {
		margin-bottom: 10px;
	}
</style>
