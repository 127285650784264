<template>
	<div>
		<div style="margin-bottom: 10px;display: flex;justify-content: flex-end;">
			<Form inline ref="searchForm" :model="searchForm" :label-width="70">
				<FormItem label="操作类型">
					<Select multiple v-model="searchForm.type" class="w_300">
						<Option v-for="(item,index) in typeList" :value="item" :key="item">
							{{item}}
						</Option>
					</Select>
				</FormItem>
				<FormItem label="商品名称">
					<Input v-model="searchForm.name" class="w_200"></Input>
				</FormItem>
				<FormItem v-model="searchForm.gcode" label="店内码">
					<Input class="w_200"></Input>
				</FormItem>
				<FormItem label="只看赠品">
					<Checkbox v-model="searchForm.onGift"></Checkbox>
				</FormItem>
			</Form>
			<Button style="margin-right: 10px;" @click="()=>handleTableData()" type='primary'>查询</Button>
			<Button @click="()=>showCreateModal()">创建</Button>
		</div>
		<Table stripe :columns='tableData.title' :data='tableData.list'>
			<template slot-scope="{row}" slot="onLongTerm">
				<div v-if="row.onLongTerm">{{'长期销售'}}</div>
				<div v-else="row.onLongTerm">
					<div>{{row.timeEnd}}</div>
					<div>{{row.timeSale}}</div>
				</div>
			</template>
			<template slot-scope="{row}" slot="priceshow">
				<div>售价:{{row.price}}</div>
				<div style="color: rgb(246, 202, 157);">会员价:{{row.memberPrice}}</div>
			</template>
			<template slot-scope="{row}" slot="onGift">
				<div v-if="row.onGift" style="color: red;">{{'是'}}</div>
				<div v-else="row.onGift">{{'否'}}</div>
			</template>
			<template slot-scope="{row}" slot="salesChannel">
				<div v-for="(item,index) in  row.salesChannel" :key="index">{{item}}</div>
			</template>
			<template slot-scope="{row}" slot="onState">
				<i-Switch @on-change="(value)=>onSale(value,row)" v-model="row.onState"></i-Switch>
			</template>
			<template slot-scope="{row}" slot="operation">
				<Button @click="()=>showEditModal(row)" type="primary" size='small' style="margin-right: 10px;">编辑</Button>
				<Poptip transfer confirm title="确定是否删除?" @on-ok="handleDelete(row)">
					<Button  type='error' size='small'>删除</Button>
				</Poptip>
			</template>
		</Table>
		<Card style='text-align: right;'>
			<Page @on-change="(value)=>handleTableData(value)" :page-size='tableData.size' :total='tableData.total'>
			</Page>
		</Card>
		<Modal :title='createOrEditTitle' width="750" :mask-closable='false' :closable="false" v-model="createModal">
			<Form ref="form" :model="form" :label-width="120">
				<Tabs transfer type="card" v-model="tabs">
					<TabPane :index='0' name='基本信息' label="基本信息">
						<FormItem  prop="sortnum" label="排序">
							<Input :disabled="formEditDisableCompute('sortnum')" v-model="form.sortnum" type='number' class="w_300"></Input>
						</FormItem>
						<FormItem prop="gcode" label="店内码">
							<Input :disabled="formEditDisableCompute('gcode')"  v-model="form.gcode" class="w_300"></Input>
						</FormItem>
						<FormItem prop="name" label="卖品名称">
							<Input :disabled="formEditDisableCompute('name')" v-model="form.name" class="w_300"></Input>
						</FormItem>
						<FormItem prop="groupid" label="卖品分组">
							<Select :disabled="formEditDisableCompute('groupid')" transfer v-model="form.groupid" class="w_300">
								<Option v-for="(item,index) in GroupType" :value="item.value" :key="item.value">
									{{item.label}}
								</Option>
							</Select>
						</FormItem>
						<FormItem prop="type" label="卖品类型">
							<Select :disabled="formEditDisableCompute('type')" transfer v-model="form.type" class="w_300">
								<Option v-for="(item,index) in PresetValue.goodstype" :value="item" :key="item">
									{{item}}
								</Option>
							</Select>
						</FormItem>

						<FormItem prop="describe" label="简要描述">
							<Input :disabled="formEditDisableCompute('describe')" v-model="form.describe" class="w_300"></Input>
						</FormItem>
						<div style="display: flex;">
							<FormItem prop="price" label="售价(元)">
								<Input :disabled="formEditDisableCompute('price')" v-model="form.price" class="w_100"></Input>
							</FormItem>
							<FormItem prop="memberPrice" label="会员价(元)">
								<Input :disabled="formEditDisableCompute('memberPrice')" v-model="form.memberPrice" class="w_100"></Input>
							</FormItem>
						</div>
						<div style="display: flex;">
							<FormItem prop="onGift" label="是否为赠品">
								<Checkbox :disabled="formEditDisableCompute('onGift')" class="w_100" v-model="form.onGift"></Checkbox>
							</FormItem>
							<FormItem prop="onState" label="上架">
								<Checkbox :disabled="formEditDisableCompute('onState')" class="w_100" v-model="form.onState"></Checkbox>
							</FormItem>
						</div>
					</TabPane>
					<TabPane :index='1' name="配方表" v-if="form.type=='休闲食品'||form.type=='周边商品'||form.type=='现调商品'"
						label="配方表">
						<div style="text-align: right;margin-bottom: 10px;">
							<Button size='small' @click="()=>showgoodsModal()">增加</Button>
						</div>
						<div style="height: 280px;overflow-y: scroll;">
							<Table :columns="createtitle" :data="form['recipeTable']">
								<template slot-scope="{row,index}" slot="operation">
									<Button @click="()=>deleterecipeTable(index)" type="error" size="small">删除</Button>
								</template>
								<template slot-scope="{row,index}" slot="val">
									<Input  v-model="form.recipeTable[index]['val']" type="number"
										style="width: 80px;"></Input>
								</template>
							</Table>
						</div>

					</TabPane>
					<TabPane :index='2' name="选项表" v-if="form.type=='组合套餐'" label="选项表">
						<div style="text-align: right;margin-bottom: 10px;">
							<Button @click="()=>showaddOrchangeModal('添加')" style="margin-right: 5px;"
								size="small">添加</Button>
						</div>
						<div style="height: 280px;overflow-y: scroll;">
							<Table :update-show-children="true" @on-expand-tree="(id,state)=>onExpandTree(id,state)"
								:columns="columns" :data="form.options" row-key="index">
								<template slot-scope="{row,index}" slot="addprice">
									<div v-if="row.addprice==undefined">{{"默认"}}</div>
									<div v-else>
										<Input  type="number"
											v-model="form.options[row.pindex].children[index].addprice" />
									</div>
								</template>
								<template slot-scope="{row,index}" slot="operation">
									<div style="display: flex;justify-content: flex-end;">
										<Button @click="()=>showaddOrchangeModal('换购',row)" style="margin-right: 5px;"
											v-if="row.addprice==undefined" size="small">换购</Button>
										<Button @click="()=>deleteGoodsTableRow(row,index)" size="small"
											type="error">删除</Button>
									</div>
								</template>
							</Table>
						</div>
					</TabPane>
					<TabPane :index='3' name="销售限定" label="销售限定">
						<FormItem prop="salesChannel" label="销售渠道">
							<Select :disabled="formEditDisableCompute('salesChannel')" multiple transfer v-model="form.salesChannel" class="w_300">
								<Option v-for="(item,index) in PresetValue.salesChannel" :value="item" :key="item">
									{{item}}
								</Option>
							</Select>
						</FormItem>
						<FormItem prop="onLongTerm" label="长期销售">
							<Checkbox :disabled="formEditDisableCompute('onLongTerm')" class="w_100" v-model="form.onLongTerm"></Checkbox>
						</FormItem>
						<FormItem prop="timeSale" v-if="!form.onLongTerm" label="开始时间">
							<DatePicker :disabled="formEditDisableCompute('timeSale')" :value="form.timeSale" transfer @on-change="(value)=>form.timeSale=value"
								format="yyyy-MM-dd HH:mm:ss" type="datetime" class="w_200"></DatePicker>
						</FormItem>
						<FormItem prop="timeEnd" v-if="!form.onLongTerm" label="结束时间">
							<DatePicker :disabled="formEditDisableCompute('timeEnd')" :value="form.timeEnd" transfer @on-change="(value)=>form.timeEnd=value"
								format="yyyy-MM-dd HH:mm:ss" type="datetime" class="w_200"></DatePicker>
						</FormItem>

					</TabPane>
					<TabPane :index='4' name="小程序" v-if="form.salesChannel.indexOf('小程序')!=-1" label="小程序">
						<div style="height: 320px;overflow-y: scroll;">
							<FormItem prop="minApp.iconurl" label="卖品图片">
								<div style="display: flex;width: 260px;">
									<div style="width: 100px;height: 100px;margin-right: 50px;">
										<img  style="width: 100%;height: 100%;" :src="form.minApp.iconurl" />
									</div>
									<qiniuUploadBtn   :qiniu_config="qiniu_config"
										@set_url="(value)=>form.minApp.iconurl=value" />
								</div>
							</FormItem>
							<FormItem  label="卖品标签">
								<div style="display: flex;">
									<ColorPicker  transfer style="margin-right: 20px;" v-model="minApptag.color" />
									<Input  style="margin-right: 20px;width: 140px;" v-model="minApptag.content" ></Input>
									<Button @click="()=>addminApptag()">添加</Button>
								</div>
								<div>
									<Tag @on-close="()=>minApptagClose(index)" v-for="(item,index) in form.minApp.tag" :color="item.color" :key="index" type="dot" closable>{{item.content}}</Tag>
								</div>
							</FormItem>
							<FormItem prop="minApp.vmsales" label="虚拟交易量">
								<Input type="number" v-model="form.minApp.vmsales" class="w_300"></Input>
							</FormItem>
							<FormItem prop="minApp.quota" label="限购数量">
								<Input type="number" v-model="form.minApp.quota" class="w_300"></Input>
							</FormItem>
							<FormItem prop="minApp.paytype" label="支付方式">
								<Select transfer multiple v-model="form.minApp.paytype" class="w_300">
									<Option v-for="(item,index) in minApppaytype" :value="item" :key="item">
										{{item}}
									</Option>
								</Select>
							</FormItem>
							<FormItem prop="minApp.aNewUser" label="限定新用户购买">
								<Checkbox v-model="form.minApp.aNewUser"></Checkbox>
							</FormItem>
							<FormItem prop="minApp.tNewUser" v-if="form.minApp.aNewUser" label="新用户划分时间">
								<DatePicker :value="form.minApp.tNewUser" transfer @on-change="(value)=>form.minApp.tNewUser=value"
									format="yyyy-MM-dd HH:mm:ss" type="datetime" class="w_200"></DatePicker>
							</FormItem>
							<fuwenben ref="fuwenben" @changevalue="(value)=>form.minApp.details=value"/>
						</div>
					</TabPane>
				</Tabs>
			</Form>
			<div slot="footer">
				<Button @click="()=>createModal=false">取消</Button>
				<Button @click="()=>handleCreate()" type="primary">确定</Button>
			</div>
		</Modal>
		<Modal title='选择商品' width="850" :mask-closable='false' :closable="false" v-model="goodsModal">
			<div style="margin-bottom: 10px;display: flex;justify-content: space-between;">
				<Checkbox  v-model="Eventheselected">连选模式</Checkbox>
				<div style="display: flex;">
					<Form inline ref="searchForm" :model="searchForm" :label-width="70">
						<FormItem label="商品名称">
							<Input  v-model="goodsName" class="w_200"></Input>
						</FormItem>
					</Form>
					<Button @click="()=>handleGetGoodBaseList()">查询</Button>
				</div>
			</div>
			<Table stripe :columns='goodsTableData.title' :data='goodsTableData.list'>
				<template slot-scope="{row}" slot="operation">
					<Button :disabled="form.recipeTable.filter(item=>item.name==row.name).length>0"
						@click="()=>choose(row)" type="primary" size='small' style="margin-right: 10px;">选择</Button>
				</template>
			</Table>
			<Card style='text-align: right;'>
				<Page @on-change="(value)=>handleGetGoodBaseList(value)" :page-size='goodsTableData.size'
					:total='goodsTableData.total'></Page>
			</Card>
			<div slot="footer">
				<Button @click="()=>goodsModal=false">关闭</Button>
			</div>
		</Modal>
		<Modal :title="addOrchange+'卖品'" width="900" :mask-closable='false' :closable="false"
			v-model="addOrchangeModal">
			<div>
				<Form ref="goodsListForm" :model="goodsListForm" :label-width="70">
					<div style="display: flex;justify-content: flex-start;">
						<FormItem prop="name" label="选项名">
							<Input  :disabled="addOrchange=='换购'" placeholder="可自定义选项名" v-model="projectName"
								class="w_200"></Input>
						</FormItem>
					</div>
					<div style="display: flex;justify-content: flex-end;">
						<FormItem prop="name" label="卖品名称">
							<Input  v-model="goodsListForm.name" class="w_100"></Input>
						</FormItem>
						<FormItem prop="gcode" label="店内码">
							<Input  v-model="goodsListForm.gcode" class="w_100"></Input>
						</FormItem>
						<Button style="margin-left: 10px;" @click="()=>handlegoodsListForm()">查找</Button>
					</div>
				</Form>
			</div>
			<Table stripe :columns='goodsListTableData.title' :data='goodsListTableData.list'>
				<template slot-scope="{row}" slot="onLongTerm">
					<div v-if="row.onLongTerm">{{'长期销售'}}</div>
					<div v-else="row.onLongTerm">
						<div>{{row.timeEnd}}</div>
						<div>{{row.timeSale}}</div>
					</div>
				</template>
				<template slot-scope="{row}" slot="priceshow">
					<div>售价:{{row.price}}</div>
					<div style="color: rgb(246, 202, 157);">会员价:{{row.memberPrice}}</div>
				</template>
				<template slot-scope="{row}" slot="onGift">
					<div v-if="row.onGift" style="color: red;">{{'是'}}</div>
					<div v-else="row.onGift">{{'否'}}</div>
				</template>
				<template slot-scope="{row}" slot="salesChannel">
					<div v-for="(item,index) in  row.salesChannel" :key="index">{{item}}</div>
				</template>
				<template slot-scope="{row}" slot="onState">
					{{row.onState?'已上架':'未上架'}}
				</template>
				<template slot-scope="{row,index}" slot="operation">
					<Button :disabled="addOrchange=='换购'&&huangouCopute(row)" @click="()=>insetGoodsTableData(row)"
						type="primary" size='small' style="margin-right: 10px;">选择</Button>
				</template>
			</Table>
			<Card style='text-align: right;'>
				<Page ref="addOrchangepage" @on-change="(value)=>handlegoodsListForm(value)"
					:page-size='goodsListTableData.size' :total='goodsListTableData.total'></Page>
			</Card>
			<div slot="footer">
				<Button @click="()=>addOrchangeModal=false">关闭</Button>
			</div>
		</Modal>
	
	</div>
</template>

<script>
	import {
		CreateGoodsGather,
		GetGoodsGatherList,
		GetPresetValue,
		GoodGroupType,
		GetGoodBaseList,
		getupTK,
		EditGoodsGather
	} from "@/request/api"
	import qiniuUploadBtn from '@/components/qiniu_upload_btn.vue'
	import fuwenben from '@/components/fuwenben.vue'
	export default {
		name: "Sellproductmanagement",
		components:{
			qiniuUploadBtn,
			fuwenben
		},
		data() {
			return {
				createOrEditTitle:"",
				qiniu_config: {}, //表单状态数据
				minApptag:{
					"color":"#000000",
					"content":""
				},
				minApppaytype:[],
				currentOptions: "",
				projectName: "",
				goodsListTableData: "",
				goodsListForm: {
					"size": 5, //每页尺寸 [5,10,15]任选 忽略为15
					"type": [
						'休闲食品', '周边商品', '现调商品'
					],
					"name": "", //模糊查找商品名称
					"gcode": "", //模糊查找店内码
					"onGift": false, //只看赠品赠品
					"title": [
						"gcode",
						"name",
						"type",
						"onState",
						"onLongTerm",
						"priceshow",
						"onGift"
					]
				},
				addOrchange: "",
				addOrchangeModal: false,
				columns: [{
						title: '选项组',
						key: 'projectName',
						tree: true,
						width: 100
					},
					{
						title: '店内码',
						key: 'gcode'
					},
					{
						title: '类型',
						key: 'type'
					},
					{
						title: '卖品名称',
						key: 'name',
						width: 150
					},
					{
						title: '加减价格（+/-）',
						key: 'addprice',
						slot: "addprice",
						width: 150
					},
					{
						title: '操作',
						key: 'operation',
						slot: "operation",
						width: 130
					}

				],
				Eventheselected: false,
				goodsName: "",
				goodsTableData: "",
				goodsModal: false,
				tabs: "基本信息",
				recipeTable: {
					"gid": "", //配方表-商品id
					"name": "", //配方表-商品名称
					"unit": "", //配方表-单位
					"val": "" //配方表-用量
				},
				createtitle: [{
						"key": "gid",
						"title": "商品ID"
					}, {
						"key": "name",
						"title": "商品名称"
					}, {
						"key": "unit",
						"title": "单位"
					},
					{
						"key": "val",
						"title": "用量",
						"slot": "val"
					},
					{
						"title": "操作",
						"key": "operation",
						"slot": "operation",
						"width": 100
					}
				],
				formCopy:{},
				form: {
					"gcode": "", //店内码
					"name": "", //卖品名称
					"groupid": "", //卖品分组ID
					"type": "", //卖品类型
					"sortnum": "", //排序
					"describe": "", //简要描述
					"onGift": false, //是否为赠品【一旦确定为赠品不可修改】
					"price": "", //价格
					"memberPrice": "", //会员价
					"salesChannel": ["网售终端"], //销售渠道 ["网售终端","网售终端","分销商"]
					"onState": true, //是否上架
					"onLongTerm": true, //是否长期销售
					"timeSale": "", //开始时间
					"timeEnd": "", //结束时间
					"recipeTable": [], //卖品配方表
					"options": [],
					"minApp": {
						"iconurl": "", //卖品图片
						"tag": [], //卖品标签
						"details": "", //卖品详情介绍富文本
						"vmsales": "", //虚拟交易量
						"paytype": [], //小程序支付方式 使用获取预设值接口获取 ("type":"小程序付款式")
						"quota": "", //限购数量
						"aNewUser": true, //限定新用户购买
						"tNewUser": "" //新用户划分时间
					} //小程序销售渠道字段
				},
				GroupType: [],
				createModal: false,
				typeList: ['全部', '休闲食品', '周边商品', '现调商品', '组合套餐'],
				searchForm: {
					"type": ["全部"], //操作类型['全部','休闲食品','周边商品','现调商品','组合套餐'] 五选一
					"name": "", //模糊查找商品名称
					"gcode": "", //模糊查找店内码
					"onGift": false //只看赠品赠品
				},
				PresetValue: "",
				tableData: "",
			}
		},
		computed: {
			formEditDisableCompute(){
				return (value)=>{
					if(this.createOrEditTitle=='创建') return false
					if(!this.form._onEditField) return false
					if(this.form._onEditField.indexOf(value)!=-1){
						return true
					}else{
						return false
					}
					
				}
			},
			huangouCopute() {
				return (row) => {
					let arr = []
					// 当前有子表格
					if (this.form.options[this.currentOptions.index].children != undefined) {
						this.form.options[this.currentOptions.index].children.forEach(item => {
							arr.push(item.id)
						})
					}
					if (this.currentOptions.id == row._id) {
						arr.push(row._id)
					}
					if (arr.indexOf(row._id) != -1) return true
					return false
				}
			}
		},
		watch: {
			["form.minApp.details"](value){
				if(value){
					this.$nextTick(()=>{
						this.$refs.fuwenben.value=this.form.minApp.details
					})
				}
			},
			addOrchangeModal(value) {
				this.$refs["goodsListForm"].resetFields()
				this.goodsListTableData = ""
			},
			createModal(value) {
				if (!value) {
					this.$refs["form"].resetFields()
					this.reset()
					return
				}
			
			},
			goodsModal() {
				this.goodsName = ''
				this.Eventheselected = false
			}
		},
		created() {
			this.formCopy = JSON.parse(JSON.stringify(this.form))
			this.to_getupTK()
			this.handleGetPresetValue()
			this.handleGoodGroupType()
			this.handleGetpaytype()
			this.handleTableData()
		},
		methods: {
			/**
			 * 删除表格行
			 */
			async handleDelete(row){
				const {_id} = row
				await EditGoodsGather({
					id:_id,
					edit_type:"删除"
				})
				const page = this.tableData.page
				this.handleTableData(page)
			},
			/**
			 * 上下架
			 */
			async onSale(value,row){
				const {_id} = row
				await EditGoodsGather({
					id:_id,
					edit_type:"上下架"
				})
				const page = this.tableData.page
				this.handleTableData(page)
			},
			/**
			 * 表格行编辑详情获取
			 */
			async handleEditGoodsGather(id){
				let res = await EditGoodsGather({
				  "id": id, //分组记录ID []
				  "edit_type": "获取详情" //['获取详情','删除','上下架','编辑内容']编辑类型
				})
				if(res.errcode!=0) return
				console.log(res.data,'==res.data')
				res.data.minApp = Object.assign(this.form.minApp,res.data.minApp)
				
				
			
				this.form = res.data
		
				
			},
			// 获取七牛云token
			async to_getupTK() {
				let { data: res_data } = await getupTK()
				this.qiniu_config = res_data.data
			},
			/**
			 * 小程序卖品标签删除
			 */
			minApptagClose(value){
				this.form.minApp.tag = this.form.minApp.tag.filter((item,index)=>index!=value) 
			},
			/**
			 * 小程序卖品颜色标签添加
			 */
			addminApptag(){
				const {color,content} = this.minApptag
				if(!color||!content){
					this.$Message.warning("请输入商品名称")
					return
				}
				this.form.minApp.tag.push({
					"color":color,
					"content":content
				})
			},
			/**
			 * 处理展开事件
			 */
			onExpandTree(id, state) {
				this.form.options.forEach(item => {
					if (item.index == id) {
						if (state) {
							item._showChildren = true
						} else {
							delete item._showChildren
						}
					}
				})
			},
			/**
			 * 删除选项表表格行
			 */
			deleteGoodsTableRow(row, index) {
				let option = []
				// 删除表格行组
				if (row.pindex == undefined) {
					option = this.form.options.filter(item => item.index != row.index)
					option.forEach((itemout, indexout) => {
						if (itemout.children != undefined) {
							itemout.children.forEach((item, index) => {
								item.pindex = indexout
							})
						}
					})
					// option.forEach((itemout,indexout)=>{
					// 	itemout.children.forEach((item,index)=>{
					// 		item.pindex = indexout
					// 	})
					// })
				}
				// 删除表格行
				if (row.pindex != undefined) {
					// 当前二级表格行
					option = JSON.parse(JSON.stringify(this.form.options))
					option[row.pindex].children = option[row.pindex].children.filter((itemout, indexout) => indexout !=
						index)
				}
				this.form.options = option
			},
			/**
			 * 选择插入卖品表格
			 */
			insetGoodsTableData(row) {
				const {
					_id,
					gcode,
					name,
					type
				} = row
				if (this.addOrchange == '添加') {
					this.form.options.push({
						"id": _id, //卖品ID
						"gcode": gcode, //店内码
						"projectName": this.projectName, //选项组名称
						"name": name, //卖品名称
						"type": type, //卖品类型
						"index": this.form.options.length
					})
				}
				if (this.addOrchange == '换购') {
					this.form.options.forEach((item, index) => {
						if (item['children'] == undefined) item['children'] = []
						if (index == this.currentOptions.index) {
							item['children'].push({
								"id": _id, //卖品ID
								"gcode": gcode, //店内码
								"name": name, //卖品名称
								"type": type, //卖品类型
								"addprice": 0, //替换卖品加减价格 正加价格 负减价格 0不变
								"pindex": index
							})
						}
					})
					this.resetGoodsTableOption()
				}
				this.addOrchangeModal = false
			},
			/**
			 * 重制选项表响应式表格数据
			 */
			resetGoodsTableOption() {
				let center = JSON.parse(JSON.stringify(this.form.options))
				this.form.options = []
				this.form.options = center
			},
			/**
			 * 选项卡卖品表格
			 */
			async handlegoodsListForm(page = 1) {
				let passData = JSON.parse(JSON.stringify(this.goodsListForm))
				passData["page"] = page
				let res = await GetGoodsGatherList(passData)
				if (res.errcode != 0) return
				res.data.title.forEach(item => {
					if (item.key == 'onLongTerm') {
						item.slot = "onLongTerm"
						item.width = 150
					}
					if (item.key == "priceshow") {
						item.slot = "priceshow"
						item.width = 100
					}
					if (item.key == "onGift") {
						item.slot = "onGift"
					}
					if (item.key == 'salesChannel') {
						item.slot = "salesChannel"
						item.width = 100
					}
					if (item.key == "onState") {
						item.slot = "onState"
					}
				})
				res.data.title.push({
					title: "操作",
					key: "operation",
					slot: "operation",
				})
				this.goodsListTableData = res.data
			},
			reset() {
				this.form = JSON.parse(JSON.stringify(this.formCopy))
				this.tabs = "基本信息"
				this.minApptag={
						"color":"#000000",
						"content":""
					}
				this.form.minApp.iconurl = this.PresetValue.noPictureurl
				this.form.minApp.paytype = JSON.parse(JSON.stringify(this.minApppaytype))
				
				
			},
			async handleCreate() {
				let passData = JSON.parse(JSON.stringify(this.form))
				let res;
				if(this.createOrEditTitle=="编辑"){
					passData['id'] = passData._id
					passData['edit_type'] = "编辑内容"
					res = await EditGoodsGather(passData)
				}else{
					res = await CreateGoodsGather(passData)
				}
				
				if (res.errcode != 0) return
				this.handleTableData()
				this.createModal = false
			},
			deleterecipeTable(value) {
				this.form['recipeTable'].splice(value, 1)
			},
			choose(row) {
				const {
					_id,
					name,
					unit
				} = row
				this.form.recipeTable.push({
					gid: _id,
					name,
					unit,
					val: 0
				})
				if (!this.Eventheselected) {
					this.goodsModal = false
				}
			},
			/**
			 * 获取货物列表
			 */
			async handleGetGoodBaseList(page = 1) {
				let res = await GetGoodBaseList({
					"page": page, //分页页码，数字类型
					"all": false, //是否全部导出
					"name": this.goodsName, //商品名称(提供模糊搜索)
					"size": 5
				})
				if (res.errcode != 0) return
				res.data.title.shift()
				res.data.title.push({
					title: "操作",
					key: "operation",
					slot: "operation",
					width: 80
				})
				this.goodsTableData = res.data

			},
			/**
			 * 弹窗显示编辑
			 */
			async showEditModal(row) {
				const {_id} = row
				await this.handleEditGoodsGather(_id)
				this.createOrEditTitle = '编辑'
				this.createModal = true
			},
			/**
			 * 弹窗现实选项表 添加或换购
			 */
			showaddOrchangeModal(type, row) {
				this.addOrchange = type
				this.projectName = "选项" + (this.form.options.length + 1)
				if (type == "换购") {
					this.currentOptions = row
					this.projectName = row.projectName

				}
				this.handlegoodsListForm()
				this.addOrchangeModal = true
			},
			/**
			 * 弹窗显示添加卖品
			 */
			async showgoodsModal() {
				await this.handleGetGoodBaseList()
				this.goodsModal = true
			},
			/**
			 * 弹窗显示创建
			 */
			showCreateModal() {
				this.createOrEditTitle = '创建'
				this.createModal = true
			},
			/**
			 * 获取小程序支付方式配置
			 */
			async handleGetpaytype(){
				let res = await GetPresetValue({
					"type": "小程序付款方式"
				})
				if (res.errcode != 0) return
				this.minApppaytype = res.data.minAppPayType
				this.form.minApp.paytype=res.data.minAppPayType
			},
			/**
			 * 获取卖品分组选项配置
			 */
			async handleGoodGroupType() {
				let res = await GoodGroupType({
					"type": "获取选项组"
				})
				if (res.errcode != 0) return
				this.GroupType = res.data
			},
			/**
			 * 获取表格数据
			 */
			async handleTableData(page = 1) {
				let passData = JSON.parse(JSON.stringify(this.searchForm))
				passData["page"] = page
				let res = await GetGoodsGatherList(passData)
				if (res.errcode != 0) return
				res.data.title.forEach(item => {
					if (item.key == 'onLongTerm') {
						item.slot = "onLongTerm"
						item.width = 150
					}
					if (item.key == "priceshow") {
						item.slot = "priceshow"
						item.width = 100
					}
					if (item.key == "onGift") {
						item.slot = "onGift"
					}
					if (item.key == 'salesChannel') {
						item.slot = "salesChannel"
						item.width = 100
					}
					if (item.key == "onState") {
						item.slot = "onState"
					}
				})
				res.data.title.push({
					title: "操作",
					key: "operation",
					slot: "operation",
					width: 150
				})
				this.tableData = res.data
			},
			/**
			 * 获取预设值配置
			 */
			async handleGetPresetValue() {
				let res = await GetPresetValue({
					type: '卖品管理'
				})
				if (res.errcode != 0) return
				this.PresetValue = res.data
				this.form.minApp.iconurl = this.PresetValue.noPictureurl
				
			},
		}
	}
</script>

<style>
	.ivu-form-item {
		margin-bottom: 6px !important;
	}
</style>
