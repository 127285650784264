<template>
	<div>
		<div style="text-align: right;margin-bottom: 10px;">
			<Button @click="()=>showCreateModal()" type="primary">创建</Button>
			<Button style="margin-left: 10px;">导出</Button>
		</div>
		<Table stripe :columns='CupOrderList.title' :data='CupOrderList.list'>
			<template slot-scope="{row}" slot="operation">
				<Button @click="()=>handleCouponOrderData(row)" type="primary" size="small">查看</Button>
			</template>
		</Table>
		<Card style='text-align: right;'>
			<Page @on-change="(value)=>handleCouponOrderList(value)" :page-size='CupOrderList.size' :total='CupOrderList.total'></Page>
		</Card>
		<Modal :mask-closable='false' :closable="false" fullscreen v-model="createModal">
			<div style="display: flex;">
				<Card style="width: 500px;margin-right: 20px;">
					<Form ref="form" :model="form" style="width: 400px;" :label-width="80">
						<FormItem prop="Name" label="票券名称">
							<Input v-model="form.Name"></Input>
						</FormItem>
						<FormItem prop="type" label="生成方式">
							<Select v-model="form.type">
								<Option v-for="(item,index) in cupOrderPresetValue.type" :value="item" :key="item">
									{{item}}
								</Option>
							</Select>
						</FormItem>
						<FormItem prop="satrt"  label="有效时间">
							<div style="display: flex;">
								<DatePicker v-model="form.satrt" type="date"></DatePicker>
								<div style="padding: 0 20px;"> - </div>
								<DatePicker v-model="form.end" type="date"></DatePicker>
							</div>
						</FormItem>
						<FormItem prop="containSaleItemType" label="兑换类型">
							<Select clearable v-model="form.containSaleItemType">
								<Option v-for="(item,index) in cupOrderPresetValue.containSaleItemType" :value="item"
									:key="item">{{item}}
								</Option>
							</Select>
						</FormItem>
						<FormItem prop="codetype" label="票券类型">
							<Select v-model="form.codetype">
								<Option v-for="(item,index) in cupOrderPresetValue.codetype" :value="item" :key="item">
									{{item}}
								</Option>
							</Select>
						</FormItem>
						<FormItem prop="totalAddPrice" :label="form.containSaleItemType=='充值券'?'充值量':'优惠量'">
							<Input v-model="form.totalAddPrice" type="number"></Input>
						</FormItem>
						<FormItem prop="preparePay"  label="预付单价">
							<Input :disabled="form.codetype=='VIP兑换券'" v-model="form.preparePay" type="number"></Input>
						</FormItem>
						<FormItem prop="total" label="申请数量">
							<Input v-model="form.total" type="number"></Input>
						</FormItem>
						<FormItem prop="oaNo" label="OA单号">
								<Input v-model="form.oaNo" type="number"></Input>
							</FormItem>
						<div style="display: flex;align-items: center;">
							<FormItem  prop="onBind" style="margin-bottom:0" label="不可绑定">
								<i-Switch v-model="form.onBind">
									</i-Switch<>
							</FormItem>
							<FormItem prop="open" style="margin-bottom:0;margin-right: 10px;" label="开放接入">
								<i-Switch v-model="form.open">
									</i-Switch<>
							</FormItem>
							<div style="color: #ccc;">
								预付金额:{{price}}
							</div>
						</div>
					
					</Form>
				</Card>
				<Card style="flex: 1;">
					<h3>可用范围</h3>
					<Select :disabled="form.containSaleItemType=='卖品券'" style="width: 70%;margin-bottom: 10px;" v-model="form.freeList" multiple>
						<Option v-for="(item,index) in cupOrderPresetValue.freeList" :value="item.value"
							:key="item">{{item.label}}
						</Option>
					</Select>
					<div v-if="form.containSaleItemType=='电影券'" style="margin-bottom: 20px;">
						<h3>影片兑换拓展
							<span style="font-size: 12px;">
								仅限拓展内容兑换<i-Switch></i-Switch>
							</span>
						</h3>
						
						<Table stripe :columns='cupFilm.title' :data='cupFilm.list'>
							<template slot="operation">
								<Button type="error" size="small">删除</Button>
							</template>
						</Table>
					</div>
					<div v-if="form.containSaleItemType=='电影券'">
						<h3>时段兑换拓展<span style="font-size: 12px;">
								仅限拓展内容兑换<i-Switch></i-Switch>
							</span></h3>
							
						<Table stripe :columns='cupfilmTime.title' :data='cupfilmTime.list'>
							<template slot="operation">
								<Button  @click="()=>cupfilmTimeDelete()" type="error" size="small">删除</Button>
							</template>
						</Table>
					</div>
					<div v-if="form.containSaleItemType=='卖品券'">
						<h3>卖品兑换<span style="font-size: 12px;">
								卖品兑换券必须设置兑换表
							</span></h3>
						<Table stripe :columns='maipinduihuan.title' :data='maipinduihuan.list'>
							<template slot="operation">
								<Button type="error" size="small">删除</Button>
							</template>
						</Table>
					</div>
				</Card>
			</div>
			<div slot="footer">
				<Button @click="()=>createModal=false">取消</Button>
				<Button @click="()=>handleCouponOrderCreate()" type="primary">确定</Button>
			</div>
		</Modal>
	</div>
</template>

<script>
	import {
		CouponOrderCreate,
		CouponOrderPresetValue,
		CouponOrderList,
		CouponOrderData
	} from "@/request/api"
	import {
		download
	} from '@/utils/table_download.js'
	export default {
		name: "Tickettoapplyfor",
		data() {
			return {
				form: {
					"oaNo": "", //OA单号
					"Name": "", //套票名称
					"type": "", //生成方式
					"satrt": "", //开始时间
					"end": "", //过期时间
					"containSaleItemType": "", //兑换类型
					"codetype": "", //票券类型
					"preparePay": "", //预付单价
					"total": "", //申请数量
					"totalAddPrice": "", //优惠金额
					"mcode": "", //所属门店
					"freeList": [], //有效范围门店
					"onBind": false, //不允许绑定 默认false
					"open": false //允许开放接口调用  默认false
				},
				maipinduihuan: {
					title: [{
							"key": "cname",
							"title": "影院名称"
						},{
							"key": "id",
							"title": "gid"
						}, {
							"key": "name",
							"title": "商品名称"
						}, {
							"key": "billing",
							"title": "全部抵扣"
						},
						{
							"key": "addprice",
							"title": "加价"
						}, {
							"key": "operation",
							"title": "操作",
							"slot": "operation"
						},

					],
					list: []
				},
				cupfilmTime: {
					title: [{
							"key": "daylabel",
							"title": "日期"
						}, {
							"key": "satrt",
							"title": "开始时段"
						}, {
							"key": "end",
							"title": "结束时段"
						}, {
							"key": "addprice",
							"title": "加价"
						},
						{
							"key": "operation",
							"title": "操作",
							"slot": "operation"
						},

					],
					list: []
				},
				cupFilm: {
					title: [{
							"key": "id",
							"title": "影片编码"
						}, {
							"key": "name",
							"title": "影片名"
						}, {
							"key": "addprice",
							"title": "加价"
						},
						{
							"key":"operation",
							"title":"操作",
							"slot":"operation"
						}

					],
					list: []
				},
				CupOrderList: [],
				createModal: false,
				cupOrderPresetValue: "",
			}
		},
		computed:{
			price(){
				return this.form.total*this.form.preparePay
			}
		},
		watch:{
			
			"form.containSaleItemType":function(value){
				
				if(value=='卖品券'){
					this.form.freeList=[]
				}
			},
			"form.codetype":function(value){
				if(value=='VIP兑换券'){
					this.form.preparePay=0
				}
			},
			createModal(){
				this.$refs["form"].resetFields()
			}
		},
		created() {
			this.handleCouponOrderList()
		},
		methods: {
			// 查看表格行数据
			async handleCouponOrderData(value){
				let res = await CouponOrderData({
					apply:value.apply
				})
				if(res.errcode!=0) return
				await this.handleCouponOrderPresetValue()
				console.log(res)
				this.form=res.data
				this.cupFilm.list = res.data.Expand_movie
				this.cupfilmTime.list = res.data.Expand_times
				this.createModal=true
			},
			async handleCouponOrderCreate(){
				let passData=JSON.parse(JSON.stringify(this.form))
				passData["satrt"]=new Date(passData['satrt']).getTime()
				passData["end"]=new Date(passData['end']).getTime()
				let res = await CouponOrderCreate(passData)
				if(res.errcode!=0) return
				this.$Message.success(res.msg)
				this.createModal=false
				this.handleCouponOrderList()
				
			},
			async showCreateModal() {
				await this.handleCouponOrderPresetValue()
				this.createModal = true
			},
			async handleCouponOrderPresetValue() {
				let res = await CouponOrderPresetValue({"type": "票券订单" })
				if (res.errcode != 0) return
				this.cupOrderPresetValue = res.data
			},
			async handleCouponOrderList(page = 1) {
				let res = await CouponOrderList({
					page,
					all: false
				})
				if (res.errcode != 0) return
				res.data.title.push({
					title: "操作",
					key: "operation",
					slot: "operation"
				})
				this.CupOrderList = res.data
			},
			async table_dowload(type = "获取数据", typecode, weight) {
				// let config =['修改登录','修改授权','修改密码']
				// let pass_data={
				// 	weight,
				// 	typecode,
				// 	"type": type, //['获取数据','同步数据','修改登录','修改授权','重置密码','修改密码']
				// 	"all": true, //是否全部导出【获取数据】
				// }
				// let res =  await PayType(pass_data)
				// if(res.errcode!=0) return
				// if(config.indexOf(type)!=-1){
				// 	this.$mytoast("操作成功")
				// }
				// if(type=="获取数据"){
				let headers = res.data.title
				let data = res.data.list
				download({
					'worksheet': '交易类型',
					headers,
					data
				})
			},

		},

	}
</script>

<style>
</style>
