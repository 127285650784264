import Vue from 'vue'
import dialog_config from './dialog.json'
let location_list = ['/','/login','/stay_single_fill_in'];//前端token放行列表
import ajax_config  from '../../../url.json';
let Auth  = ajax_config.Auth;// 准入令牌【约定】字符

/**
 * 字符串自动生成div	
 * @param {string} txt dom内的文本
 */
export function stringToDom(txt='') {
    var obj = document.createElement("div"); 
    obj.innerHTML = txt; 
    obj.id='loading'
    return obj; 
}


/**
 * 动画加载显示
 * @param {string}  pass_info	加载动画下方附加的文字
 */
export let  show_loading = (pass_info) => {
	Vue.nextTick(()=>{
		
		document.getElementById('loading').innerHTML = `
		<div   style="width:100vw;height:100vh;position: absolute;top: 50%;left: 50%;z-index: 999999;transform: translate(-50%,-50%);text-align: center;">
				<div style="position: absolute;top: 50%;left: 50%;transform: translate(-50%,-50%);">
					<div >
						<img src="${dialog_config['加载动画']}" >
					</div>
					<span style="color: green;" >
						${pass_info?pass_info:''}
					</span>
				</div>
		</div>
		`
	})

	
}; //显示菊花加载图标


/**
 * 动画加载隐藏  
 */
export let  hide_loading = () => {
	document.getElementById('loading').innerHTML = ''
}; 


/**
 * token丢失打回登录页函数
 * 
 */
export let lose_token_back_to_login = () =>{

	// 判断未被放心的前端路由
	if(location_list.indexOf(window.location.pathname)==-1){

		// 如果本地没有token就打回
		if(!localStorage.getItem(Auth)){
	
			window.location.replace('/')
		}
	}
	
}




// 后端接口显示响应头非 200 弹出警告提示
export let api_show_not_200 = (err_msg) => {

		if(typeof err_msg == 'string'){
			Vue.prototype.$mytoast_err(err_msg)
		}else{
			Vue.prototype.$mytoast_err("未知错误")
		}
	
}
