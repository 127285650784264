<template>
	<div>
		<div style="text-align: right;">
			<Button @click="()=>to_getEquipment()">全部</Button>
			<Button style="margin-left: 10px;" @click="()=>to_getEquipment('消费插件')">消费插件</Button>
			<Button style="margin-left: 10px;" @click="()=>to_getEquipment('取票机')">取票机</Button>
			<Input   v-model="code" placeholder="请输入设备码" style="width: 200px;margin-left: 10px;"></Input>
			<Button style="margin-left: 10px;" @click="()=>add()" type='primary'>添加</Button>
		</div>
		<div style="margin-top: 10px;">
			<Table :columns='title' :data="data"></Table>
		</div>
		<!-- 对话框 -->
		<Modal :closable='false' :mask-closable='false' v-model="is_show_modal_add">
			<div>
				<Input v-model="ip" placeholder="请输入ip" style="width: 200px;"></Input>
				<Input v-model="port" placeholder="请输入端口" style="width: 200px;"></Input>
			</div>
			<div slot='footer'>
				<Button @click="()=>is_show_modal_add=false">返回</Button>
				<Button @click="()=>to_add()"  type="primary">确定</Button>
			</div>
		</Modal>
	</div>
</template>

<script>
	import { setEquipment,getEquipment } from '@/request/api.js'
	export default{
		name:'navbar',
		data() {
			return {
				is_show_modal_add:false,//
				code:'',//设备码
				title:[],
				data:[],
				ip:'',
				port:''
			}
		},
		mounted(){
			this.to_getEquipment()
		},
		methods: {
			// 执行最后的添加操作
			async to_add(){
				let pass_data = {
					"mcode":localStorage.getItem('mcode'),
					"code":this.code,
					"ip":this.ip,
					"port":this.port
				}
				let res_data = await setEquipment(pass_data)
				if(res_data.code==200){
					this.to_getEquipment()
					this.is_show_modal_add = false
				}
				
			},
			async add(){
				let pass_data = {
					"mcode":localStorage.getItem('mcode'),
					"code":this.code
				}
				
				let res_data = await setEquipment(pass_data)
				if(res_data.data=='添加简取票缺少参数'){
					this.is_show_modal_add = true
				}
				
			},			
			// 获取设备表格数据
			async to_getEquipment(type=''){
				let pass_data = {
					"mcode": localStorage.getItem('mcode'),
					"type":type
				}
				
				let {data:res_data} = await getEquipment(pass_data)
				this.title = res_data.title
				this.data = res_data.data
				
			}
			
		},
	}
</script>

<style>
</style>
