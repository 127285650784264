import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import Login from '@/views/Login.vue'
import paiqi from '@/views/content/functions/paiqi.vue'


import UserManagement from '@/views/content/UserManagement.vue'
import RoleManagement from '@/views/content/RoleManagement.vue'
import RuleManagement from '@/views/content/RuleManagement.vue'

import filmPrice from '@/views/content/functions/filmPrice.vue'
import memberPrepaidPhoneButton from '@/views/content/functions/memberPrepaidPhoneButton.vue'
import offlinePaymentOrders from '@/views/content/functions/offlinePaymentOrders.vue'
import order from '@/views/content/functions/order.vue'
import sellProduct from '@/views/content/functions/sellProduct.vue'
import ticketQuery from '@/views/content/functions/ticketQuery.vue'
import update from '@/views/content/functions/update.vue'
import db from '@/views/content/functions/db.vue'
import select_stores from '@/views/content/functions/select_stores.vue'
import refund from '@/views/content/functions/refund.vue'
import store_settings from '@/views/content/functions/store_settings.vue'
import brand_management from '@/views/content/functions/brand_management.vue'
import store_management from '@/views/content/functions/store_management.vue'
import goods_detail from '@/views/content/functions/goods_detail.vue'
import goods_type from '@/views/content/functions/goods_type.vue'
import schedule_the_ticket_sales from '@/views/content/functions/schedule_the_ticket_sales.vue'
import new_data_summary from '@/views/content/functions/new_data_summary.vue'
import ticket_order from '@/views/content/functions/ticket_order.vue'
import Tickettoapplyfor from '@/views/content/functions/Tickettoapplyfor.vue'
import ticket_query from '@/views/content/functions/ticket_query.vue'
import ting_management from '@/views/content/functions/ting_management.vue'
import pricing_strategy from '@/views/content/functions/pricing_strategy.vue'
import goods_sales_subsidiary from '@/views/content/functions/goods_sales_subsidiary.vue'
import member_the_query from '@/views/content/functions/member_the_query.vue'
import equipment_management from '@/views/content/functions/equipment_management.vue'
import membership_card_queries from '@/views/content/functions/membership_card_queries.vue'
import configuration_management from '@/views/content/configuration_management.vue'
import distributors from '@/views/content/functions/distributors'
import stay_single_fill_in from '@/views/content/functions/stay_single_fill_in'
import activity_codes from '@/views/content/functions/activity_codes'
import account from '@/views/content/functions/account'
import paytype from '@/views/content/functions/paytype'
import charged from '@/views/content/functions/charged'
import yuanGonQrcode from '@/views/content/functions/yuanGonQrcode'
import Inventorymanagement from '@/views/content/functions/Inventorymanagement'
import Groupmanagement from '@/views/content/functions/Groupmanagement'
import Sellproductmanagement from '@/views/content/functions/Sellproductmanagement'
import membership_card_queriesv1 from '@/views/content/functions/membership_card_queriesv1'
import member_the_queryv1 from '@/views/content/functions/member_the_queryv1'

import nofound from "../components/error-page/404.vue"



import ticket_setting from '@/views/content/ticket_setting.vue'
import {GetButMandate} from '../request/api.js'

Vue.use(VueRouter)

const routes = [
	{
		  path: '/404',
		  component:nofound
	},
	{
	  path: '*',
	  redirect: '/404'
	},
	{
			path: '/stay_single_fill_in',
			component: stay_single_fill_in,
		},
	{
		path: '/',
		redirect:'/login'
	},
	
	{
		path: '/home',
		component: Home,
		children: [
			{
				path: '/member_the_queryv1',
				component:member_the_queryv1
			},
			{
				path: '/membership_card_queriesv1',
				component:membership_card_queriesv1
			},
			{
				path: '/Sellproductmanagement',
				component:Sellproductmanagement
			},
		{
			path: '/Groupmanagement',
			component:Groupmanagement
		},
		{
			path: '/Inventorymanagement',
			component:Inventorymanagement
		},
		{
			path: '/yuanGonQrcode',
			component:yuanGonQrcode
		},
		{
			path: '/charged',
			component:charged
		},
			{
				path: '/paytype',
				component:paytype
			},
			{
				path: '/account',
				component:account
			},
			{
				path: '/paiqi',
				component:paiqi
			},{
				path: '/activity_codes',
				component: activity_codes,
			},{
				path: '/distributors',
				component: distributors,
			},{
				path: '/equipment_management',
				component: equipment_management,
			},{
				path: '/configuration_management',
				component: configuration_management,
			},{
				path: '/ticket_setting',
				component: ticket_setting,
			},{
				path: '/membership_card_queries',
				component: membership_card_queries,
			},{
				path: '/member_the_query',
				component: member_the_query,
			},{
				path: '/goods_sales_subsidiary',
				component: goods_sales_subsidiary,
			},{
				path: '/pricing_strategy',
				component: pricing_strategy,
			},{
				path: '/ting_management',
				component: ting_management,
			},{
				path: '/ticket_query',
				component: ticket_query,
			},{
				path: '/ticket_order',
				component: ticket_order,
			},{
				path: '/Tickettoapplyfor',
				component: Tickettoapplyfor,
			},{
				path: '/schedule_the_ticket_sales',
				component: schedule_the_ticket_sales,
			},{
				path: '/new_data_summary',
				component: new_data_summary,
			},{
				path: '/goods_type',
				component: goods_type,
			},{
				path: '/goods_detail',
				component: goods_detail,
			},{
				path: '/user/management',
				component: UserManagement,
			}, {
				path: '/role/management',
				component: RoleManagement,
			}, {
				path: '/rule/management',
				component: RuleManagement,
			}, {
				path: '/refund',
				component: refund,
			}, {
				path: '/filmPrice',
				component: filmPrice,
			}, , {
				path: '/memberPrepaidPhoneButton',
				component: memberPrepaidPhoneButton,
			}, , {
				path: '/offlinePaymentOrders',
				component: offlinePaymentOrders,
			}, , {
				path: '/order',
				component: order,
			}, , {
				path: '/sellProduct',
				component: sellProduct,
			}, , {
				path: '/ticketQuery',
				component: ticketQuery,
			}, {
				path: '/update',
				component: update,
			}, {
				path: '/db',
				component: db,
			},
			{
				path: '/store_settings',
				component: store_settings
			},
			{
				path: '/brand_management',
				component: brand_management
			},
			{
				path: '/store_management',
				component: store_management
			}
		]
	},
	{
		path: '/login',
		component: Login
	},
	{
		path: '/select_stores',
		component: select_stores
	}
]

const router = new VueRouter({
	mode: 'history',
	routes
})

router.beforeEach(async (to,from,next)=>{
	
	let notUrl = [
		'/login',
		'/home',
		'user/management',
		'/select_stores',
		'/stay_single_fill_in'
	]
	let nav = JSON.parse(localStorage.getItem('选中品牌1'))
	
	if(nav&&notUrl.indexOf(to.path)==-1){
		// 对侧边导航
		nav = nav.nat;
		let pageuid;
		nav.forEach(item=>{
			item.child.forEach(itemin=>{
				
				if(to.path=='/'+itemin.name){
					pageuid = itemin.uid
				}
			})
		})
		let type= JSON.parse(localStorage.getItem('type'))
		
		if(type==2){
			let pass_data = {
				'mcode':localStorage.getItem('mcode'),
				'pageuid':pageuid
			}
			
			
			// 发送权限请求
			let res_data = await GetButMandate(pass_data)
			if(res_data.code==200){
				localStorage.setItem('current_page_button',JSON.stringify(res_data.data))
				
			}
		}
		
	}

    next();
})

export default router
